.OurStory {
  background-color: #ffffff;
  padding: 86px 100px 90px;
}
.isMobile .OurStory {
  padding: 40px 0;
}
.OurStory-wrapper {
  width: 100%;
  position: relative;
}
.OurStory-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.isMobile .OurStory-container {
  display: none;
}
.OurStory-containerYears {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.OurStory-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
.isMobile .OurStory-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.OurStory-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  margin-left: -4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  will-change: transform;
}
.isMobile .OurStory-title {
  font-size: 36px;
  line-height: 40px;
}
.dark .OurStory-title {
  color: #ffffff;
}
.OurStory-title.isNormal {
  width: calc(100% - 20px);
  margin-right: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #383633;
}
.isMobile .OurStory-title.isNormal {
  color: #383633;
}
.OurStory-descr {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  width: calc(100% - 20px);
  margin-left: 20px;
  line-height: 25px;
  font-weight: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 30px;
}
.isMobile .OurStory-descr {
  font-size: 16px;
  line-height: 25px;
}
.isMobile .OurStory-descr {
  width: 100%;
  margin-left: 0;
  margin-top: 35px;
}
.OurStory-descr h2 {
  font-size: 36px;
  line-height: 40px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #383633;
  letter-spacing: 0em;
  font-weight: normal;
}
@media print {
  .OurStory-descr h2 {
    color: #000000;
  }
}
.isMobile .OurStory-descr h2 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.OurStory-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 65px;
  font-family: "CharterITCStd";
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.isMobile .OurStory-content {
  margin-top: 10px;
}
.OurStory-content :last-child .OurStory-text {
  border-bottom: 0;
}
.isMobile .OurStory-content :last-child .OurStory-text {
  margin-bottom: 0;
}
.OurStory-year {
  font-family: "FFMarkWebPro";
  font-size: 200px;
  letter-spacing: -0.1em;
  font-weight: normal;
  color: #383633;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: calc(100% - 20px);
  margin-right: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 0 0px;
  max-width: calc(50% - 20px);
}
@media (min-width: 1180px) and (max-width: 1380px) {
  .OurStory-year {
    font-size: 160px;
  }
}
@media (max-width: 1179px) {
  .OurStory-year {
    font-size: 140px;
  }
}
.isMobile .OurStory-year {
  font-size: 64px;
  line-height: 1;
  padding-top: 0;
  margin-right: 0;
  margin-top: -5px;
}
.OurStory-year .isNormal {
  color: transparent;
}
.isMobile .OurStory-year .isNormal {
  color: #383633;
}
.OurStory-year .isFix {
  position: absolute;
}
.OurStory-yearFix {
  position: -webkit-sticky;
  position: sticky;
  font-family: "FFMarkWebPro";
  font-size: 200px;
  top: 0;
  letter-spacing: -0.1em;
  font-weight: normal;
  color: #383633;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1180px) and (max-width: 1380px) {
  .OurStory-yearFix {
    font-size: 160px;
  }
}
@media (max-width: 1179px) {
  .OurStory-yearFix {
    font-size: 140px;
  }
}
.isMobile .OurStory-yearFix {
  display: none;
  position: absolute;
}
.OurStory-text {
  font-size: 24px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  width: calc(100% - 20px);
  margin-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 60px 0 70px;
  border-bottom: 1px solid #d8d8d8;
}
.isMobile .OurStory-text {
  padding-top: 27px;
  padding-bottom: 40px;
  margin-bottom: 33px;
  width: 100%;
  margin-left: 0;
  font-size: 16px;
  line-height: 25px;
}
.OurStory-text a {
  text-decoration: none;
  color: #f5371f;
}
@media print {
  .OurStory-text a {
    color: #000000;
  }
}
.isDesktop .OurStory-text a:hover {
  text-decoration: underline;
}
.OurStory-text b {
  width: 100%;
  display: block;
}
.OurStory-text ul {
  list-style: disc;
}
.isMobile .OurStory-text ul {
  margin-left: 20px;
}
.OurStory-text ul li {
  margin-top: 1.7em;
}
.OurStory-text ul li:first-child {
  margin-top: 0;
}
.OurStory-text ol {
  list-style: decimal;
}
.isMobile .OurStory-text ol {
  margin-left: 20px;
}
.OurStory-text ol li {
  margin-top: 1.7em;
}
.OurStory-text ol li:first-child {
  margin-top: 0;
}
.OurStory-textImg {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.OurStory-textImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 63.63636364%;
}
.moreImgs .OurStory-textImg {
  position: relative;
  width: calc(50% - 10px);
}
.moreImgs .OurStory-textImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 157.14285714%;
}
.OurStory-textImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.OurStory-textImgWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 43px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .OurStory-textImgWrapper {
  margin-top: 25px;
}
.noText .OurStory-textImgWrapper {
  margin-top: 0;
}
.OurStory-showMore {
  color: #f5371f;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -0.9px;
}
.OurStory-showMoreWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 66px;
}
.OurStory-showMore:hover {
  text-decoration: underline;
}
