.SearchClientDashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.SearchClientDashboard-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  background: #ffffff;
  padding-left: 100px;
}
.isMobile .SearchClientDashboard-sidebar {
  padding-left: 20px;
}
.SearchClientDashboard-sidebar--right {
  padding-left: 100px;
}
.isMobile .SearchClientDashboard-sidebar--right {
  padding-left: 20px;
}
.SearchClientDashboard-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}
.SearchClientDashboard-bodyWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.isMobile .SearchClientDashboard-bodyWrapper {
  display: block;
}
.SearchClientDashboard-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  padding-top: 86px;
  background: #ffffff;
}
.isMobile .SearchClientDashboard-header {
  padding-top: 40px;
}
.SearchClientDashboard-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  padding-top: 115px;
  background: #ffffff;
}
.isMobile .SearchClientDashboard-footer {
  padding-top: 77px;
}
.SearchClientDashboard-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background: #ffffff;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66%;
          flex: 0 0 66%;
}
.isMobile .SearchClientDashboard-info {
  width: 100%;
  background: none;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
.SearchClientDashboard-infoHeader {
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SearchClientDashboard-infoHeaderImg {
  position: relative;
  width: 60px;
  height: 80px;
  display: none;
}
.isMobile .SearchClientDashboard-infoHeaderImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SearchClientDashboard-infoHeaderImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.SearchClientDashboard-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  background-color: #ffffff;
}
.isMobile .SearchClientDashboard-title {
  font-size: 36px;
  line-height: 40px;
}
.isMobile .SearchClientDashboard-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  background: #ffffff;
  padding-right: 10px;
  padding-bottom: 35px;
}
.SearchClientDashboard-descr {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #383633;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  margin-top: 80px;
  width: 100%;
}
.isMobile .SearchClientDashboard-descr {
  font-size: 16px;
  line-height: 25px;
}
.isMobile .SearchClientDashboard-descr {
  width: 100%;
  margin-top: 0px;
  padding-top: 39px;
  background: #ffffff;
}
.SearchClientDashboard-link {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #f5371f;
  text-decoration: none;
  margin-top: 20px;
  width: 73.83333333%;
}
.isMobile .SearchClientDashboard-link {
  font-size: 16px;
  line-height: 20px;
}
.isMobile .SearchClientDashboard-link {
  background: #ffffff;
  margin-top: 0px;
  padding-top: 27px;
  width: 100%;
}
.SearchClientDashboard-link:hover {
  text-decoration: underline;
}
.SearchClientDashboard-flexFiller {
  background-color: #ffffff;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  min-width: 40px;
}
.isMobile .SearchClientDashboard-flexFiller {
  display: none;
}
.SearchClientDashboard-flexFillerMobile {
  display: none;
}
.isMobile .SearchClientDashboard-flexFillerMobile {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  background-color: #ffffff;
}
.SearchClientDashboard-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
  max-width: 250px;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .SearchClientDashboard-section {
  width: 100%;
  max-width: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.SearchClientDashboard-sectionHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: #ffffff;
  height: 3.47222222vw;
}
.isMobile .SearchClientDashboard-sectionHeader {
  display: none;
}
.SearchClientDashboard-sectionBlock {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.SearchClientDashboard-sectionBlock:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 44.30147059%;
}
.isMobile .SearchClientDashboard-sectionBlock {
  display: block;
  margin: 0 auto;
  width: 70%;
  max-width: 300px;
}
.SearchClientDashboard-sectionBlock img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
