.JoinPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 26;
  -webkit-transition: 0s visibility 0.2s ease-out;
  transition: 0s visibility 0.2s ease-out;
  overflow-scrolling: touch;
  visibility: hidden;
}
.JoinPopup--visible {
  visibility: visible;
  -webkit-transition: none;
  transition: none;
}
.JoinPopup--visible .JoinPopup-content {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
}
.JoinPopup--visible .JoinPopup-contentInner {
  opacity: 1;
  -webkit-transition: 0.2s opacity 0.4s ease-out;
  transition: 0.2s opacity 0.4s ease-out;
}
.JoinPopup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.isMobile .JoinPopup-bg {
  display: none;
}
.JoinPopup-wrapper {
  width: 48.61111111%;
  min-width: 500px;
  margin-left: auto;
  position: relative;
  overflow-scrolling: touch;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 11;
}
.isMobile .JoinPopup-wrapper {
  min-width: 100%;
  width: 100%;
}
.JoinPopup-wrapper.overflowLock {
  overflow: hidden;
}
.JoinPopup-content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #383633;
  outline: none;
  overflow-scrolling: touch;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
.IndexPage .JoinPopup-content {
  background: #f4f3f1;
}
.isMobile .JoinPopup-content {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.JoinPopup-contentInner {
  opacity: 0;
  padding: 86px 13.88888889% 86px 22.22222222%;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.isMobile .JoinPopup-contentInner {
  padding: 54px 20px 50px;
}
.JoinPopup-close {
  position: fixed;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.isMobile .JoinPopup-close {
  top: 20px;
  right: 20px;
}
.JoinPopup-close:hover .JoinPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  width: 34px;
}
.JoinPopup-close:hover .JoinPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  width: 34px;
}
.JoinPopup-closeItem {
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  background: #ffffff;
}
.IndexPage .JoinPopup-closeItem {
  background: #383633;
}
.JoinPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.JoinPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.isMobile .JoinPopup-close {
  display: block;
}
.JoinPopup-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #ffffff;
  text-transform: none;
}
.isMobile .JoinPopup-title {
  font-size: 36px;
  line-height: 40px;
}
.IndexPage .JoinPopup-title {
  color: #383633;
}
.JoinPopup-jobLinks {
  width: 283px;
  margin-top: 34px;
  margin-bottom: 13px;
  position: relative;
}
.isMobile .JoinPopup-jobLinks {
  width: 66%;
}
.JoinPopup-jobLink {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.JoinPopup-jobLink:hover .JoinPopup-arrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.JoinPopup-jobLinkSeparator {
  width: 100%;
  height: 1px;
  background: #ffffff;
  opacity: 0.2;
  margin-bottom: 9px;
  margin-top: 21px;
}
.JoinPopup-jobLink a,
.JoinPopup-jobLink span {
  color: #f5371f;
  font-size: 36px;
  font-family: "FFMarkWebPro";
  text-decoration: none;
  font-weight: 500;
  letter-spacing: -1.6px;
}
.isMobile .JoinPopup-jobLink a,
.isMobile .JoinPopup-jobLink span {
  font-size: 24px;
}
.JoinPopup-jobLink a:visited,
.JoinPopup-jobLink span:visited {
  color: #f5371f;
}
.JoinPopup-arrow {
  width: 69px;
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  right: 0;
  margin-top: 5px;
}
.isDesktop .JoinPopup-arrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .JoinPopup-arrow {
  width: 48px;
  height: 26px;
}
.JoinPopup-arrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .JoinPopup-arrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .JoinPopup-arrow {
  width: 40px;
  height: 20px;
  cursor: pointer;
  overflow: hidden;
}
.isDesktop .isMobile .JoinPopup-arrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .isMobile .JoinPopup-arrow {
  width: 48px;
  height: 26px;
}
.isMobile .JoinPopup-arrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-5px, 0, 0);
          transform: translate3d(-5px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .isMobile .JoinPopup-arrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .JoinPopup-arrow svg {
  width: 100%;
  height: 100%;
}
.JoinPopup-jobDescription {
  margin-top: 50px;
  font-size: 18px;
  font-family: "CharterITCStd";
  color: #ffffff;
  line-height: 1.667;
}
.isMobile .JoinPopup-jobDescription {
  margin-top: 40px;
  font-size: 16px;
  width: 100%;
}
.IndexPage .JoinPopup-jobDescription {
  color: #383633;
}
.JoinPopup-shareDropdown {
  width: 100%;
  position: absolute;
  top: 109%;
  left: 0;
  background-color: #ff3333;
  visibility: hidden;
  opacity: 0;
}
@-webkit-keyframes dropdownFadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes dropdownFadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.JoinPopup-shareDropdown.active {
  -webkit-animation: dropdownFadeIn 0.3s forwards;
          animation: dropdownFadeIn 0.3s forwards;
}
.JoinPopup-shareLink {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: #ffffff;
  font-size: 18px;
  font-family: "FFMarkWebPro";
  padding: 7px 19px 11px 19px;
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  display: block;
  font-weight: 500;
  letter-spacing: -1px;
  cursor: pointer;
}
.JoinPopup-shareLink:hover {
  opacity: 0.7;
}
.JoinPopup-shareLink:last-child {
  border-bottom: none;
}
