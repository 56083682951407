.RecentPlacementSection-wrapper {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.isOpenMenu .RecentPlacementSection-wrapper {
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
