.NewsList {
  background-color: #ffffff;
  padding: 86px 100px 115px;
  min-height: 100vh;
}
.isMobile .NewsList {
  padding: 39px 0 60px;
}
.NewsList.dark {
  background-color: #333333;
}
.NewsList-wrapper {
  width: 100%;
}
.NewsList-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #333333;
  margin-left: -4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .NewsList-title {
  font-size: 36px;
  line-height: 40px;
}
.dark .NewsList-title {
  color: #ffffff;
}
.NewsList-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 65px;
  font-family: "CharterITCStd";
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .NewsList-content {
  margin-top: 15px;
}
.NewsList-showMore {
  color: #f5371f;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -0.9px;
}
.NewsList-showMoreLoading {
  display: none;
  width: 20px;
  height: 20px;
}
.NewsList-showMore.loading {
  pointer-events: none;
}
.NewsList-showMore.loading .NewsList-showMoreLoading {
  display: block;
}
.NewsList-showMore.loading span {
  display: none;
}
.isMobile .NewsList-showMore {
  font-size: 16px;
  line-height: 20px;
}
.NewsList-showMoreWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 66px;
}
.isMobile .NewsList-showMoreWrapper {
  margin-top: 42px;
}
.NewsList-showMore:hover {
  text-decoration: underline;
}
