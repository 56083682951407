.TeamMemberPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 26;
  -webkit-transition: 0s visibility 0.2s ease-out;
  transition: 0s visibility 0.2s ease-out;
  overflow-scrolling: touch;
  visibility: hidden;
}
.TeamMemberPopup--visible {
  visibility: visible;
  -webkit-transition: none;
  transition: none;
}
.TeamMemberPopup--visible .TeamMemberPopup-content {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
}
.TeamMemberPopup--visible .TeamMemberPopup-contentInner {
  opacity: 1;
  -webkit-transition: 0.2s opacity 0.4s ease-out;
  transition: 0.2s opacity 0.4s ease-out;
}
.TeamMemberPopup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.TeamMemberPopup-wrapper {
  width: 75%;
  min-width: 500px;
  margin-left: auto;
  position: relative;
  overflow-scrolling: touch;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 11;
}
.isMobile .TeamMemberPopup-wrapper {
  min-width: 100%;
  width: 100%;
}
.TeamMemberPopup-wrapper.overflowLock {
  overflow: hidden;
}
.TeamMemberPopup-content {
  background: #383633;
  position: relative;
  width: 100%;
  min-height: 100vh;
  outline: none;
  overflow-scrolling: touch;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
.isMobile .TeamMemberPopup-content {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.TeamMemberPopup-contentInner {
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.TeamMemberPopup-close {
  position: fixed;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  z-index: 26;
  background: #383633;
}
.isMobile .TeamMemberPopup-close {
  top: 20px;
  right: 20px;
}
.TeamMemberPopup-close:hover .TeamMemberPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  width: 34px;
}
.TeamMemberPopup-close:hover .TeamMemberPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  width: 34px;
}
.TeamMemberPopup-closeItem {
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  background: #ffffff;
}
.TeamMemberPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.TeamMemberPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.isMobile .TeamMemberPopup-close {
  display: block;
}
.TeamMemberPopup-image {
  width: 100%;
  height: 0;
  padding-bottom: 45%;
  overflow: hidden;
  position: relative;
}
.isMobile .TeamMemberPopup-image {
  display: none;
}
.TeamMemberPopup-imagePlaceholder {
  width: 100%;
  height: 50px;
}
.isMobile .TeamMemberPopup-imagePlaceholder {
  display: none;
}
.TeamMemberPopup-imageSquare {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 440px;
          flex: 0 0 440px;
  height: 0;
  padding-bottom: 440px;
  overflow: hidden;
  position: relative;
}
.isMobile .TeamMemberPopup-imageSquare {
  display: none;
}
.TeamMemberPopup-imageSquareMobile {
  display: none;
}
.isMobile .TeamMemberPopup-imageSquareMobile {
  display: block;
  margin-top: 60px;
}
.TeamMemberPopup-imageSquareMobile img {
  width: 100%;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.TeamMemberPopup-imageSquare img {
  width: 100%;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.TeamMemberPopup-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -o-object-fit: cover;
     object-fit: cover;
}
.TeamMemberPopup-memberTitleWrapper {
  position: relative;
}
.isMobile .TeamMemberPopup-memberTitleWrapper {
  min-height: 132px;
}
.TeamMemberPopup-memberTitleWrapper.memberSquareImage {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 44%;
          flex: 0 0 44%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .TeamMemberPopup-memberTitleWrapper.memberSquareImage {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
.TeamMemberPopup-memberInfo {
  padding: 40px 80px 0 50px;
  position: relative;
}
.TeamMemberPopup-memberInfoHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .TeamMemberPopup-memberInfoHead {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.isMobile .TeamMemberPopup-memberInfo {
  padding: 50px 20px 28px;
}
.TeamMemberPopup-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #ffffff;
  text-transform: none;
  margin-left: -5px;
}
.isMobile .TeamMemberPopup-title {
  font-size: 36px;
  line-height: 40px;
}
.TeamMemberPopup-subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.isMobile .TeamMemberPopup-subtitle {
  display: block;
  margin-top: 10px;
  padding-top: 0;
}
.TeamMemberPopup-memberTeam {
  font-family: "FFMarkWebPro";
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: -1px;
}
.isMobile .TeamMemberPopup-memberTeam {
  line-height: normal;
}
.TeamMemberPopup-memberPosition {
  font-family: "FFMarkWebPro";
  font-size: 16px;
  color: #999999;
  letter-spacing: -1px;
  font-weight: 500;
}
.isMobile .TeamMemberPopup-memberPosition {
  line-height: normal;
}
.TeamMemberPopup-memberPositionSlash {
  display: inline;
}
.isMobile .TeamMemberPopup-memberPositionSlash {
  display: none;
}
.TeamMemberPopup-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 114px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .TeamMemberPopup-description {
  display: block;
  margin-bottom: 70px;
}
.TeamMemberPopup-quickBio {
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
}
.isMobile .TeamMemberPopup-quickBio {
  margin-bottom: 70px;
}
.TeamMemberPopup-quickBioTitle {
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #999999;
}
.isMobile .TeamMemberPopup-quickBioTitle {
  font-size: 24px;
}
.TeamMemberPopup-quickBioDescription {
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #ffffff;
  margin-top: 40px;
  line-height: 1.667;
}
.isMobile .TeamMemberPopup-quickBioDescription {
  font-size: 16px;
}
.TeamMemberPopup-quickBio a {
  color: #f5371f;
  text-decoration: none;
}
.TeamMemberPopup-quickBio a:hover {
  text-decoration: underline;
}
.TeamMemberPopup-snapshot {
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
}
.TeamMemberPopup-snapshotTitle {
  font-family: "CharterITCStd";
  font-size: 36px;
  margin-bottom: 40px;
  color: #999999;
}
.isMobile .TeamMemberPopup-snapshotTitle {
  font-size: 24px;
}
.TeamMemberPopup-snapshotDescriptionItem {
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #999999;
  margin-top: 20px;
  padding-bottom: 17px;
  border-bottom: 1px solid #4c4a48;
}
.isMobile .TeamMemberPopup-snapshotDescriptionItem {
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 25px;
  margin-top: 10px;
  line-height: 25px;
}
.isMobile .TeamMemberPopup-snapshotDescriptionItem:last-of-type {
  border-bottom: none;
}
.TeamMemberPopup-clients {
  padding-bottom: 120px;
}
.TeamMemberPopup-clientsTitle {
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #999999;
}
.isMobile .TeamMemberPopup-clientsTitle {
  font-size: 24px;
}
.TeamMemberPopup-clientsLogos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 40px;
  margin-right: -1.73611111vw;
  margin-left: -1.73611111vw;
}
.isMobile .TeamMemberPopup-clientsLogos {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}
.TeamMemberPopup-clientsLogo {
  margin-bottom: 45px;
  margin-right: 1.73611111vw;
  margin-left: 1.73611111vw;
  width: 180px;
  padding-top: 80px;
  position: relative;
}
.isMobile .TeamMemberPopup-clientsLogo {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  padding-bottom: 20%;
  padding-top: 0;
  margin-bottom: 70px;
  margin-right: 0;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.TeamMemberPopup-clientsLogo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.TeamMemberPopup-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100px;
}
.TeamMemberPopup-clock.absolute {
  margin-top: 30px;
}
.isMobile .TeamMemberPopup-clock {
  top: 60px;
  right: 20px;
  display: none;
}
.TeamMemberPopup-clockMobile {
  display: none;
}
.isMobile .TeamMemberPopup-clockMobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 120px;
}
.TeamMemberPopup-clockItem {
  width: 100%;
}
.TeamMemberPopup-clock .FooterClockItem {
  width: 100%;
  margin: auto;
}
.isMobile .TeamMemberPopup-clock .FooterClockItem {
  max-width: 70px;
}
.TeamMemberPopup-clock .FooterClockItem-clock {
  background-color: #f5371f;
}
.TeamMemberPopup-clock .FooterClockItem-clockOffice {
  margin: 0;
  width: 150%;
}
.isMobile .TeamMemberPopup-clock .FooterClockItem-clockOffice {
  width: 160%;
}
.TeamMemberPopup-clock .FooterClockItem-clockOfficeItem {
  font-size: 14px;
  margin-top: 5px;
  color: #808080;
  font-family: "CharterITCStd";
}
.isMobile .TeamMemberPopup-clock .FooterClockItem-clockOfficeItem {
  margin-top: 13px;
}
.TeamMemberPopup-clock .FooterClockItem-spire--hour {
  background-color: #383633;
}
.TeamMemberPopup-clock .FooterClockItem-spire--min {
  background-color: #383633;
}
.TeamMemberPopup-linkedIn {
  margin-top: 16px;
}
.isMobile .TeamMemberPopup-linkedIn {
  margin-left: 45px;
}
.TeamMemberPopup-flexSpacer {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
.TeamMemberPopup-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 28px;
  width: 140px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .TeamMemberPopup-social {
  display: none;
}
.TeamMemberPopup-socialEmail,
.TeamMemberPopup-socialLinkedIn {
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: -1px;
  margin-top: 14px;
  display: block;
}
.isMobile .TeamMemberPopup-socialEmail,
.isMobile .TeamMemberPopup-socialLinkedIn {
  display: none;
}
.TeamMemberPopup-socialEmail:hover,
.TeamMemberPopup-socialLinkedIn:hover {
  text-decoration: underline;
}
.TeamMemberPopup-socialLinkedIn,
.TeamMemberPopup-socialEmail {
  display: block;
  margin-top: 0;
}
.TeamMemberPopup-socialMobile {
  display: none;
}
.isMobile .TeamMemberPopup-socialMobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0;
}
.isMobile .TeamMemberPopup-socialMobileEmail,
.isMobile .TeamMemberPopup-socialMobileLinkedIn {
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: -1px;
  margin-top: 14px;
  display: block;
}
