.NewsItem {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 25px 0 30px;
  text-decoration: none;
  border-bottom: 1px solid #d8d8d8;
}
.isMobile .NewsItem {
  padding: 22px 0;
}
.NewsItem:first-child {
  padding-top: 0;
}
.NewsItem.hasImg {
  padding: 19px 0 20px;
}
.NewsItem.hasImg:first-child {
  padding: 0;
}
.isMobile .NewsItem.hasImg {
  padding: 22px 0;
}
.isDesktop .NewsItem:hover .NewsItem-dateDay {
  color: #383633;
}
.isDesktop .NewsItem:hover .NewsItem-infoTitle {
  color: #f5371f;
}
.NewsItem-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 17.39130435%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.isMobile .NewsItem-date {
  width: 20%;
}
.NewsItem-dateMonth {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #bfbfbf;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
  line-height: 25px;
}
.isMobile .NewsItem-dateMonth {
  font-size: 16px;
  line-height: 20px;
}
.NewsItem-dateDay {
  font-size: 72px;
  line-height: 60px;
  font-family: "FFMarkWebPro";
  font-weight: normal;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  margin-top: 13px;
  color: #bfbfbf;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
}
.isMobile .NewsItem-dateDay {
  font-size: 36px;
  line-height: 30px;
}
.isMobile .NewsItem-dateDay {
  margin-top: 7px;
}
.NewsItem-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 69.56521739%;
}
.isMobile .NewsItem-info {
  width: 83.33333333%;
}
.hasImg .NewsItem-info {
  width: 60.86956522%;
}
.isMobile .hasImg .NewsItem-info {
  width: 83.33333333%;
}
.NewsItem-infoTag {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #bfbfbf;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
  line-height: 25px;
}
.isMobile .NewsItem-infoTag {
  font-size: 16px;
  line-height: 20px;
}
.NewsItem-infoTitle {
  font-size: 36px;
  line-height: 40px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #383633;
  letter-spacing: 0em;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
}
@media print {
  .NewsItem-infoTitle {
    color: #000000;
  }
}
.isMobile .NewsItem-infoTitle {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.isMobile .NewsItem-infoTitle {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  line-height: 20px;
  color: #383633;
  font-weight: normal;
  margin-top: 7px;
}
.isMobile .isMobile .NewsItem-infoTitle {
  font-size: 16px;
  line-height: 25px;
}
.hasImg .NewsItem-infoTitle {
  padding-right: 20px;
}
.isMobile .hasImg .NewsItem-infoTitle {
  padding-right: 0;
}
.notTag .NewsItem-infoTitle {
  margin-top: 30px;
}
.isMobile .notTag .NewsItem-infoTitle {
  margin-top: 8px;
}
.NewsItem-img {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 21.73913043%;
}
.NewsItem-img:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 60%;
}
.isMobile .NewsItem-img {
  display: none;
}
.NewsItem-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
