.CurrentOpenings {
  background-color: #ffffff;
  padding: 86px 100px 112px;
}
.isMobile .CurrentOpenings {
  padding: 38px 0 80px;
}
.CurrentOpenings.dark {
  background-color: #383633;
}
.CurrentOpenings-wrapper {
  width: 100%;
}
.CurrentOpenings-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .CurrentOpenings-title {
  font-size: 36px;
  line-height: 40px;
}
.dark .CurrentOpenings-title {
  color: #ffffff;
}
.CurrentOpenings-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 65px;
  font-family: "CharterITCStd";
}
.isMobile .CurrentOpenings-content {
  display: block;
  margin-top: 41px;
}
.CurrentOpenings-description {
  -ms-flex-preferred-size: 26%;
      flex-basis: 26%;
  font-size: 18px;
  color: #808080;
  line-height: 1.667;
  margin-top: 30px;
}
.isMobile .CurrentOpenings-description {
  font-size: 16px;
  margin-top: 41px;
}
.CurrentOpenings-linkList {
  width: 100%;
  -ms-flex-preferred-size: 66%;
      flex-basis: 66%;
  margin-left: 80px;
}
.isMobile .CurrentOpenings-linkList {
  margin-left: 0;
  margin-top: 30px;
}
.CurrentOpenings-link {
  border-bottom: 1px #d8d8d8 solid;
  font-size: 36px;
  color: #333333;
  line-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 0;
  position: relative;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  text-decoration: none;
}
.isMobile .CurrentOpenings-link {
  font-size: 24px;
}
.dark .CurrentOpenings-link {
  color: #ffffff;
  border-color: #4c4a48;
}
.CurrentOpenings-link:hover .CurrentOpenings-linkTitle {
  color: #f5371f;
}
.CurrentOpenings-link:hover .CurrentOpenings-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.CurrentOpenings-link:last-child {
  border-bottom: none;
}
.CurrentOpenings-link:last-of-type {
  border-bottom: none;
}
.CurrentOpenings-linkTitle {
  max-width: calc(100% - 80px);
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.CurrentOpenings-linkArrow {
  width: 69px;
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  width: 60.5px;
  height: 34px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -17px;
}
.isDesktop .CurrentOpenings-linkArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .CurrentOpenings-linkArrow {
  width: 48px;
  height: 26px;
}
.CurrentOpenings-linkArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .CurrentOpenings-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.CurrentOpenings-more {
  color: #f5371f;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  margin-top: 30px;
}
.isMobile .CurrentOpenings-more {
  font-size: 16px;
}
.CurrentOpenings-more a {
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -0.9px;
  color: #f5371f;
}
.CurrentOpenings-more a:visited {
  color: #f5371f;
}
.CurrentOpenings-more a:hover {
  text-decoration: underline;
}
