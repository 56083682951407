.ContactSection-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
}
.isMobile .ContactSection-title {
  font-size: 36px;
  line-height: 40px;
}
