.ContactBlock {
  background-color: #ffffff;
  padding: 86px 100px 90px;
  min-height: 100vh;
}
.isMobile .ContactBlock {
  padding: 40px 0 0;
}
.ContactBlock-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .ContactBlock-head {
  display: block;
}
.ContactBlock-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
}
.isMobile .ContactBlock-title {
  font-size: 36px;
  line-height: 40px;
}
.ContactBlock-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 27px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
.isMobile .ContactBlock-content {
  margin-top: 0;
}
.ContactBlock-description {
  font-size: 18px;
  font-family: "CharterITCStd";
  color: #808080;
  line-height: 1.667;
  max-width: 525px;
  margin-top: 25px;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  -webkit-animation: descriptionFadeIn 0.2s forwards linear;
          animation: descriptionFadeIn 0.2s forwards linear;
}
.isMobile .ContactBlock-description {
  margin-top: 48px;
  font-size: 16px;
}
@-webkit-keyframes descriptionFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  1% {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible;
  }
}
@keyframes descriptionFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  1% {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible;
  }
}
.ContactBlock-description.hide {
  -webkit-animation: descriptionFadeOut 0.2s forwards linear;
          animation: descriptionFadeOut 0.2s forwards linear;
}
@-webkit-keyframes descriptionFadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  99% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    height: 0;
    margin: 0;
  }
}
@keyframes descriptionFadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  99% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    height: 0;
    margin: 0;
  }
}
.ContactBlock-back {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 67px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
  z-index: 20;
  -webkit-animation: backFadeIn 0.2s forwards linear;
          animation: backFadeIn 0.2s forwards linear;
}
.isMobile .ContactBlock-back {
  top: 15px;
}
@-webkit-keyframes backFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  1% {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible;
  }
}
@keyframes backFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  1% {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible;
  }
}
.ContactBlock-back.hide {
  -webkit-animation: backFadeOut 0.2s forwards linear;
          animation: backFadeOut 0.2s forwards linear;
}
@-webkit-keyframes backFadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes backFadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.ContactBlock-back:hover .ContactBlock-backArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ContactBlock-back span {
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  cursor: pointer;
  margin-left: 7px;
  padding-bottom: 3px;
}
.ContactBlock-backArrow {
  width: 20px;
  height: 15px;
  cursor: pointer;
  overflow: hidden;
}
.isDesktop .ContactBlock-backArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .ContactBlock-backArrow {
  width: 48px;
  height: 26px;
}
.ContactBlock-backArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(5px, 0, 0);
          transform: translate3d(5px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .ContactBlock-backArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ContactBlock-backArrow svg {
  width: 100%;
  height: 100%;
  fill: #f5371f;
}
.isMobile .ContactBlock-backArrow {
  width: 20px;
  height: 15px;
  cursor: pointer;
  overflow: hidden;
}
.isDesktop .isMobile .ContactBlock-backArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .isMobile .ContactBlock-backArrow {
  width: 48px;
  height: 26px;
}
.isMobile .ContactBlock-backArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(5px, 0, 0);
          transform: translate3d(5px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .isMobile .ContactBlock-backArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ContactBlock-channel {
  margin-top: 40px;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  opacity: 0;
}
.isMobile .ContactBlock-channel {
  margin-top: 48px;
}
.ContactBlock-channel.active {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ContactBlock-channel.withAnimation {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ContactBlock-channelLink {
  width: 100%;
  padding: 20px 12% 20px 0;
  border-bottom: 1px solid #cccccc;
  position: relative;
  cursor: pointer;
}
.isMobile .ContactBlock-channelLink {
  padding: 20px 25% 20px 0;
}
.ContactBlock-channelLink:hover span {
  color: #f5371f;
}
.ContactBlock-channelLink:hover .ContactBlock-channelLinkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ContactBlock-channelLink:last-child {
  border-bottom: none;
}
.ContactBlock-channelLink span {
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #383633;
  pointer-events: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.isMobile .ContactBlock-channelLink span {
  font-size: 24px;
}
.ContactBlock-channelLinkArrow {
  width: 69px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 29px;
  width: 59px;
  height: 32px;
  pointer-events: none;
}
.isDesktop .ContactBlock-channelLinkArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .ContactBlock-channelLinkArrow {
  width: 48px;
  height: 26px;
}
.ContactBlock-channelLinkArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .ContactBlock-channelLinkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.ContactBlock-channelLinkArrow svg {
  fill: #f5371f;
}
.ContactBlock-formWrapper {
  width: 100%;
  min-height: 500px;
}
.ContactBlock-formWrapper.isFields {
  margin-top: -52px;
  margin-left: 320px;
}
.isMobile .ContactBlock-formWrapper.isFields {
  margin-left: 0;
  margin-top: 0;
}
.ContactBlock-formWrapper.isLocations {
  margin-top: -52px;
  margin-left: 320px;
}
.isMobile .ContactBlock-formWrapper.isLocations {
  margin-left: 0;
  margin-top: 0;
}
.ContactBlock-formWrapper.isLocations .ContactBlock-form {
  margin-left: -320px;
}
.isMobile .ContactBlock-formWrapper.isLocations .ContactBlock-form {
  margin-left: 0;
}
.ContactBlock-formWrapper.isLocations .ContactBlock-formTitle {
  margin-bottom: 145px;
}
.isMobile .ContactBlock-formWrapper.isLocations .ContactBlock-formTitle {
  margin-bottom: 40px;
}
.ContactBlock-formWrapper.fadeIn {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-animation: fadeIn 0.2s forwards ease-in-out;
          animation: fadeIn 0.2s forwards ease-in-out;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.ContactBlock-formTitle {
  color: #383633;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  letter-spacing: -0.05em;
  font-size: 18px;
  margin-bottom: 55px;
}
.isMobile .ContactBlock-formTitle {
  margin-bottom: 30px;
  margin-top: 20px;
}
.ContactBlock-formInput {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}
.ContactBlock-formInput.required:before {
  position: absolute;
  content: '*';
  left: -20px;
  top: 20px;
  width: 10px;
  height: 10px;
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #999999;
}
.isMobile .ContactBlock-formInput.required:before {
  font-size: 15px;
  left: -15px;
}
.ContactBlock-formInput input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #bfbfbf;
  padding: 15px 0 3px;
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #383633;
}
.isMobile .ContactBlock-formInput input {
  font-size: 24px;
  -webkit-border-radius: 0;
}
.ContactBlock-formInput input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  border-bottom: 1px solid #808080;
  outline: none;
}
.ContactBlock-formInput input.invalid {
  border-color: #f5371f;
}
.ContactBlock-formInput input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ContactBlock-formInput input::-moz-placeholder {
  color: #bfbfbf;
}
.ContactBlock-formInput input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ContactBlock-formInput input::-ms-input-placeholder {
  color: #bfbfbf;
}
.ContactBlock-formInput input::placeholder {
  color: #bfbfbf;
}
.ContactBlock-formDropdown {
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid #bfbfbf;
  padding: 15px 0 10px;
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #383633;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
}
.isMobile .ContactBlock-formDropdown {
  font-size: 24px;
  padding: 15px 0 3px;
}
.ContactBlock-formDropdown.required:before {
  position: absolute;
  content: '*';
  left: -20px;
  top: 20px;
  width: 10px;
  height: 10px;
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #999999;
}
.isMobile .ContactBlock-formDropdown.required:before {
  font-size: 15px;
  left: -15px;
}
.ContactBlock-formDropdownOptions {
  width: 100%;
  background-color: #f5371f;
  position: absolute;
  top: 100%;
  z-index: 3;
  visibility: hidden;
  max-height: 500px;
  overflow-y: scroll;
}
.ContactBlock-formDropdownOption {
  color: #ffffff;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  font-size: 18px;
  padding: 8px 20px 11px 20px;
  border-bottom: 1px solid #f75f4c;
  cursor: pointer;
  letter-spacing: -1px;
  width: 100%;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.isMobile .ContactBlock-formDropdownOption {
  font-size: 15px;
  padding: 5px 15px 8px 15px;
}
.ContactBlock-formDropdownOption:hover {
  opacity: 0.7;
}
.ContactBlock-formDropdownOption:last-child {
  border: none;
}
.ContactBlock-formDropdownModel {
  pointer-events: none;
  padding-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  position: relative;
}
.ContactBlock-formDropdownModelWrapper {
  position: relative;
}
.ContactBlock-formDropdownModelWrapper.expanded + .ContactBlock-formDropdownOptions {
  -webkit-animation: optionsFadeIn 0.2s forwards ease-in-out;
          animation: optionsFadeIn 0.2s forwards ease-in-out;
}
@-webkit-keyframes optionsFadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  1% {
    visibility: visible;
    opacity: 0;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    visibility: visible;
    opacity: 1;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes optionsFadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  1% {
    visibility: visible;
    opacity: 0;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    visibility: visible;
    opacity: 1;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.ContactBlock-formDropdownModelWrapper.expanded svg {
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg);
}
.ContactBlock-formDropdownModel svg {
  width: 29px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 15px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.isMobile .ContactBlock-formDropdownModel svg {
  width: 18px;
  height: 12px;
  top: 10px;
}
.ContactBlock-formDropdownModel.placeholder {
  color: #bfbfbf;
}
.ContactBlock-submitButton {
  width: 100%;
  color: #ffffff;
  background-color: #f5371f;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  font-family: "FFMarkWebPro";
  font-size: 36px;
  font-weight: 500;
  padding: 7px 0 17px;
}
.isMobile .ContactBlock-submitButton {
  width: calc(100% + 40px);
  margin-left: -20px;
  font-size: 16px;
  padding: 24px 0;
  margin-bottom: 0;
}
.ContactBlock-submitButton.disabled {
  background-color: #e5e5e5;
  color: #ffffff;
  cursor: default;
  pointer-events: none;
}
.ContactBlock-submitButton.disabled:hover:after {
  opacity: 0;
}
.ContactBlock-submitButton:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  left: 0;
  top: 0;
  z-index: 1;
}
.ContactBlock-submitButton:hover:after {
  opacity: 0.3;
}
.ContactBlock-textArea {
  width: 100%;
  position: relative;
  height: 370px;
  margin-top: 43px;
  margin-bottom: 20px;
}
.ContactBlock-textArea.required:before {
  position: absolute;
  content: '*';
  left: -20px;
  top: 8px;
  width: 10px;
  height: 10px;
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #999999;
}
.isMobile .ContactBlock-textArea.required:before {
  font-size: 15px;
  left: -14px;
  z-index: 20;
}
.ContactBlock-textArea textarea {
  height: 100%;
  width: 100%;
  position: absolute;
  outline: none;
  border: none;
  font-family: "CharterITCStd";
  font-size: 18px;
  background-image: linear-gradient(to right, #fff 4px, transparent 1px), linear-gradient(#ccc 1px, transparent 1px);
  background-size: 8px 32px;
  background-position-y: 31px;
  background-attachment: local;
  resize: none;
  color: #333333;
  line-height: 32px;
}
.isMobile .ContactBlock-textArea textarea {
  background-color: #f9f9f9;
  background-image: none;
  margin-left: -20px;
  margin-right: -40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  min-width: 100%;
  width: calc(100% + 40px);
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 0;
  line-height: 25px;
}
.ContactBlock-textArea textarea.invalid {
  background-image: linear-gradient(to right, #fff 4px, transparent 1px), linear-gradient(#f5371f 1px, transparent 1px);
}
.isMobile .ContactBlock-textArea textarea.invalid {
  background-image: none;
}
.ContactBlock-textArea textarea::-webkit-input-placeholder {
  font-size: 18px;
  color: #cccccc;
}
.ContactBlock-textArea textarea::-moz-placeholder {
  font-size: 18px;
  color: #cccccc;
}
.ContactBlock-textArea textarea:-ms-input-placeholder {
  font-size: 18px;
  color: #cccccc;
}
.ContactBlock-textArea textarea::-ms-input-placeholder {
  font-size: 18px;
  color: #cccccc;
}
.ContactBlock-textArea textarea::placeholder {
  font-size: 18px;
  color: #cccccc;
}
.ContactBlock-bottomLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.ContactBlock-uploadButton {
  width: 100%;
  border: none;
  border-bottom: 1px solid #bfbfbf;
  padding: 15px 0 10px;
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #bfbfbf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.isMobile .ContactBlock-uploadButton {
  font-size: 24px;
  padding: 15px 0 3px;
}
.isFileAttached .ContactBlock-uploadButton {
  padding: 25px 0 20px;
}
.ContactBlock-uploadButtonUploaded {
  display: none;
  color: #bfbfbf;
  font-size: 18px;
  font-family: "CharterITCStd";
}
.isMobile .ContactBlock-uploadButtonUploaded {
  font-size: 16px;
}
.ContactBlock-uploadButtonUploaded a {
  color: #f5371f;
  text-decoration: none;
}
.ContactBlock-uploadButtonUploaded a:hover {
  text-decoration: underline;
}
.ContactBlock-uploadButtonUploaded a:visited {
  color: #f5371f;
}
.isFileAttached .ContactBlock-uploadButtonUploaded {
  display: block;
}
.isFileAttached .ContactBlock-uploadButtonPlaceholder {
  display: none;
}
.ContactBlock-uploadButtonLink {
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  cursor: pointer;
}
.isMobile .ContactBlock-uploadButtonLink {
  font-size: 16px;
}
.isFileAttached .ContactBlock-uploadButtonLink {
  display: none;
}
.ContactBlock-uploadButtonLinkUploaded {
  display: none;
}
.isFileAttached .ContactBlock-uploadButtonLinkUploaded {
  display: block;
}
.ContactBlock-uploadButtonWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.ContactBlock-uploadButtonWrapper.required:before {
  position: absolute;
  content: '*';
  left: -20px;
  top: 20px;
  width: 10px;
  height: 10px;
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #999999;
}
.isMobile .ContactBlock-uploadButtonWrapper.required:before {
  font-size: 15px;
  left: -15px;
}
.ContactBlock-uploadButtonWrapper:empty {
  display: none;
}
.ContactBlock-uploadButtonWrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.ContactBlock-uploadButtonWrapper:hover .ContactBlock-uploadButtonLink {
  text-decoration: underline;
}
.ContactBlock-updatesCheckbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 60px;
}
.isMobile .ContactBlock-updatesCheckbox {
  margin-bottom: 35px;
  margin-top: 35px;
}
.ContactBlock-updatesCheckbox span {
  margin-left: 10px;
  font-family: "CharterITCStd";
  font-size: 14px;
  color: #999999;
}
.ContactBlock-updatesCheckboxWrapper {
  width: 100%;
  max-width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.ContactBlock-updatesCheckboxWrapper:hover {
  opacity: 0.7;
}
.ContactBlock-updatesCheckboxWrapper input[type=checkbox] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.ContactBlock-updatesCheckboxWrapper input[type=checkbox]:checked + .ContactBlock-updatesCheckboxStylized:after {
  content: '';
  display: block;
  position: absolute;
  left: 6px;
  width: 8px;
  height: 13px;
  right: 7px;
  top: 2px;
  border: solid #f5371f;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  pointer-events: none;
}
.ContactBlock-updatesCheckboxStylized {
  border: 1px solid #383633;
  width: 100%;
  height: 100%;
}
.ContactBlock-formLabel {
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #808080;
  margin-top: 47px;
  margin-bottom: 40px;
}
.ContactBlock-uploadResult {
  font-family: "CharterITCStd";
  font-size: 18px;
  margin-bottom: 5px;
  display: none;
}
.ContactBlock-uploadResult.succeed {
  display: inherit;
  color: green;
}
.ContactBlock-uploadResult.failed {
  display: inherit;
  color: #f5371f;
}
.ContactBlock-success {
  position: absolute;
  left: 0;
  font-size: 36px;
  font-family: "CharterITCStd";
  color: #bfbfbf;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.isMobile .ContactBlock-success {
  font-size: 24px;
}
.ContactBlock-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 20px 0 30px;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.isMobile .ContactBlock-location {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 22px 0 38px;
}
.ContactBlock-location:first-child {
  padding-top: 0;
  border-top: 0;
}
.ContactBlock-locationTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 30.43478261%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #383633;
}
.isMobile .ContactBlock-locationTitle {
  font-size: 24px;
  width: 100%;
}
.ContactBlock-locationAddress {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  width: 30.43478261%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  font-weight: 400;
  color: #f5371f;
  margin-left: 40px;
}
.isMobile .ContactBlock-locationAddress {
  font-size: 16px;
  line-height: 25px;
}
.isMobile .ContactBlock-locationAddress {
  margin-left: 0;
  margin-top: 24px;
  width: 200px;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0;
}
a.ContactBlock-locationAddress,
.ContactBlock-locationAddress a {
  color: #f5371f;
  text-decoration: none;
}
.isDesktop a.ContactBlock-locationAddress:hover,
.isDesktop .ContactBlock-locationAddress a:hover {
  text-decoration: underline;
}
.ContactBlock-locationContacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin-left: auto;
}
.isMobile .ContactBlock-locationContacts {
  margin-left: 0;
  margin-top: 40px;
}
.ContactBlock-locationContacts a {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  font-weight: 400;
  color: #f5371f;
  text-decoration: none;
}
.isMobile .ContactBlock-locationContacts a {
  font-size: 16px;
  line-height: 25px;
}
.isMobile .ContactBlock-locationContacts a {
  width: 200px;
  font-size: 18px;
  line-height: 30px;
}
.isDesktop .ContactBlock-locationContacts a:hover {
  text-decoration: underline;
}
