.CommonItemShape {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.isMobile .CommonItemShape.isTeam {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.CommonItemShape.isGray .CommonItemShape-headerImg,
.CommonItemShape.isGray .CommonItemShape-headerImg:after {
  border: 1px solid #f4f3f1;
}
.CommonItemShape.isGray .CommonItemShape-headerSidebar {
  background: #f4f3f1;
  -webkit-box-shadow: 0 0 0 1px #f4f3f1;
          box-shadow: 0 0 0 1px #f4f3f1;
}
.CommonItemShape.isGray .CommonItemShape-body {
  background: #f4f3f1;
  -webkit-box-shadow: -2px 0 0 -1px #f4f3f1, 2px 0 0 -1px #f4f3f1;
          box-shadow: -2px 0 0 -1px #f4f3f1, 2px 0 0 -1px #f4f3f1;
}
.CommonItemShape-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.isMobile .isTeam .CommonItemShape-header {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 22%;
}
.CommonItemShape-headerImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 62.5%;
  border: 1px solid #ffffff;
  position: relative;
}
.CommonItemShape-headerImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.CommonItemShape-headerImg:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid #fff;
}
.isMobile .CommonItemShape-headerImg {
  width: 60%;
}
.isTeam .CommonItemShape-headerImg {
  position: relative;
  width: 37.5%;
}
.isTeam .CommonItemShape-headerImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 127.5%;
}
.isMobile .isTeam .CommonItemShape-headerImg {
  width: 100%;
}
.IndexPage .CommonItemShape-headerImg {
  width: 83.33333333%;
}
.CommonItemShape-headerImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.CommonItemShape-headerSidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background: #ffffff;
  width: 37.5%;
  -webkit-box-shadow: 0 0 0 1px #ffffff;
          box-shadow: 0 0 0 1px #ffffff;
}
.isMobile .CommonItemShape-headerSidebar {
  width: 20%;
}
.isTeam .CommonItemShape-headerSidebar {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.isMobile .isTeam .CommonItemShape-headerSidebar {
  height: 100%;
}
.IndexPage .CommonItemShape-headerSidebar {
  width: 16.66666667%;
}
.CommonItemShape-headerSidebar.mobVis {
  display: none;
}
.isMobile .CommonItemShape-headerSidebar.mobVis {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isMobile .isTeam .CommonItemShape-headerSidebar.mobVis {
  height: 0px;
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  padding-top: 13px;
}
.CommonItemShape-body {
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 55px;
  z-index: 1;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding-bottom: 90px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-shadow: -2px 0 0 -1px #ffffff, 2px 0 0 -1px #ffffff;
          box-shadow: -2px 0 0 -1px #ffffff, 2px 0 0 -1px #ffffff;
}
.isTeam .CommonItemShape-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-top: 32px;
  padding-bottom: 105px;
}
.isMobile .isTeam .CommonItemShape-body {
  padding-top: 0;
  padding-left: 15px;
  padding-bottom: 87px;
}
.isMobile .CommonItemShape-body {
  padding-top: 22px;
  padding-bottom: 77px;
}
:last-child .isMobile .CommonItemShape-body {
  padding-bottom: 57px;
}
.CommonItemShape-title {
  font-size: 36px;
  line-height: 40px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #383633;
  letter-spacing: 0em;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-transition: 0.2s color;
  transition: 0.2s color;
}
@media print {
  .CommonItemShape-title {
    color: #000000;
  }
}
.isMobile .CommonItemShape-title {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.isMobile .CommonItemShape-title {
  width: 100%;
}
.isSearch .CommonItemShape-title {
  width: 95%;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.CommonItemShape-descr {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  width: 81.25%;
  margin-top: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-transition: 0.2s color;
  transition: 0.2s color;
}
.isMobile .CommonItemShape-descr {
  font-size: 16px;
  line-height: 25px;
}
.isMobile .CommonItemShape-descr {
  width: 100%;
  margin-top: 2px;
}
.IndexPage .CommonItemShape-descr {
  width: 83.33333333%;
}
.CommonItemShape-link {
  text-decoration: none;
  display: block;
}
.CommonItemShape-link:hover .CommonItemShape-title {
  color: #f5371f;
}
.CommonItemShape-link:hover .CommonItemShape-descr {
  color: #383633;
}
.CommonItemShape-link:hover .CommonItemShape-arrow svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.CommonItemShape-arrow {
  width: 60px;
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  margin-top: 30px;
}
.isDesktop .CommonItemShape-arrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .CommonItemShape-arrow {
  width: 48px;
  height: 26px;
}
.CommonItemShape-arrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .CommonItemShape-arrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.CommonItemShape-arrow svg {
  width: 100%;
  height: 100%;
  fill: #f5371f;
}
.CommonItemShape-cta {
  color: #f5371f;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  margin-top: 45px;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -0.9px;
}
.isMobile .CommonItemShape-cta {
  margin-top: 55px;
}
.CommonItemShape-cta:hover {
  text-decoration: underline;
}
