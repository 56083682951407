.CommonQuote {
  position: relative;
  width: 100%;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .CommonQuote {
  width: 100%;
}
.CommonQuote--popup.isInitialState {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.CommonQuote-quote {
  font-size: 36px;
  line-height: 40px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #383633;
  letter-spacing: 0em;
  font-weight: normal;
  position: relative;
}
@media print {
  .CommonQuote-quote {
    color: #000000;
  }
}
.isMobile .CommonQuote-quote {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.CommonQuote-quote p {
  display: unset;
}
.CommonQuote-quote p:first-child {
  margin-top: 0;
}
.CommonQuote-quote:before {
  content: '“';
  color: inherit;
  position: absolute;
  left: -23px;
}
.isMobile .CommonQuote-quote:before {
  left: -13px;
}
.CommonQuote-quote:after {
  content: ' ”';
  color: inherit;
  display: inline-block;
}
.CommonQuote-author {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  margin-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-style: italic;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  color: #383633;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .CommonQuote-author {
  font-size: 16px;
  line-height: 25px;
}
@media print {
  .CommonQuote-author {
    color: #000000;
  }
}
.isMobile .CommonQuote-author {
  margin-top: 20px;
}
