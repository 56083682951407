.HistoryOfInnovation {
  background-color: #383633;
  padding: 86px 100px 90px;
}
.isMobile .HistoryOfInnovation {
  padding: 39px 0 60px;
}
.HistoryOfInnovation-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #ffffff;
}
.isMobile .HistoryOfInnovation-title {
  font-size: 36px;
  line-height: 40px;
}
.HistoryOfInnovation-link {
  text-decoration: none;
  display: block;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
  margin-left: 35%;
}
.isMobile .HistoryOfInnovation-link {
  margin-left: 0;
}
.HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionTitle {
  color: #3cbea6;
}
.HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionDescription {
  color: #ffffff;
}
.HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionDescription a {
  color: #f5371f;
}
.HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionDescription a:visited {
  color: #f5371f;
}
.DevPage .HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionDescription a {
  color: #3b6dca;
}
.DevPage .HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionDescription a:visited {
  color: #3b6dca;
}
.TechPage .HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionDescription a {
  color: #3cbea6;
}
.TechPage .HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionDescription a:visited {
  color: #3cbea6;
}
.HistoryOfInnovation-link:hover .HistoryOfInnovation-sectionArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.HistoryOfInnovation-link:last-of-type .HistoryOfInnovation-section {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.HistoryOfInnovation-section {
  position: relative;
  padding-bottom: 45px;
  margin-bottom: 45px;
  margin-top: 75px;
  border-bottom: 1px solid #808080;
  display: block;
}
.isMobile .HistoryOfInnovation-section {
  margin-left: 0;
  margin-top: 30px;
}
.isMobile .HistoryOfInnovation-sectionWrapper {
  margin-top: 50px;
}
.HistoryOfInnovation-sectionTitle {
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #ffffff;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  display: block;
}
.isMobile .HistoryOfInnovation-sectionTitle {
  font-size: 24px;
}
.HistoryOfInnovation-sectionDescription {
  margin-top: 35px;
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #ffffff;
  line-height: 1.667;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  display: block;
}
.HistoryOfInnovation-sectionDescription a {
  color: #3cbea6;
  text-decoration: none;
}
.HistoryOfInnovation-sectionDescription a:visited {
  color: #3cbea6;
}
.HistoryOfInnovation-sectionDescription a:hover {
  text-decoration: underline;
}
.isMobile .HistoryOfInnovation-sectionDescription {
  font-size: 16px;
}
.HistoryOfInnovation-sectionArrow {
  width: 69px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 59px;
  height: 32px;
  display: block;
}
.isDesktop .HistoryOfInnovation-sectionArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .HistoryOfInnovation-sectionArrow {
  width: 48px;
  height: 26px;
}
.HistoryOfInnovation-sectionArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .HistoryOfInnovation-sectionArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .HistoryOfInnovation-sectionArrow {
  width: 30px;
  height: 20px;
}
.HistoryOfInnovation-sectionArrow svg {
  fill: #3cbea6;
}
