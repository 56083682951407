.ClientLoginButton {
  position: fixed;
  left: 100px;
  bottom: 79px;
  z-index: 25;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
  display: none;
}
.SearchPage .ClientLoginButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SearchExtraPage .ClientLoginButton {
  display: none;
}
.ClientLoginButton.hide {
  opacity: 0;
  pointer-events: none;
}
.overflowLock .ClientLoginButton {
  display: none;
}
@media (min-width: 1180px) and (max-width: 1380px) {
  .ClientLoginButton {
    left: 50px;
  }
}
@media (max-width: 1179px) {
  .ClientLoginButton {
    left: 40px;
  }
}
.isMobile .ClientLoginButton {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.ClientLoginButton:hover {
  opacity: 0.7;
}
.ClientLoginButton a {
  color: #ffffff;
  text-decoration: none;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.05em;
  margin-left: 10px;
}
.ClientLoginButton a:visited {
  color: #ffffff;
}
.ClientLoginButton svg {
  width: 20px;
  height: 30px;
}
