.JoinUs {
  background: #F4F3F1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 86px 100px 74px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.isMobile .JoinUs {
  padding: 39px 0 60px;
  display: block;
}
.JoinUs-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  width: 300px;
}
.isMobile .JoinUs-title {
  font-size: 36px;
  line-height: 40px;
}
.JoinUs-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .JoinUs-content {
  display: block;
}
.JoinUs-description {
  -ms-flex-preferred-size: 48%;
      flex-basis: 48%;
  margin-left: 40px;
  margin-top: 24px;
  position: relative;
}
.isMobile .JoinUs-description {
  margin-top: 42px;
  margin-left: 0;
  width: 100%;
}
.JoinUs-descriptionText {
  font-family: CharterITCStd, sans-serif;
  font-size: 18px;
  color: #333333;
  line-height: 1.667;
}
.isMobile .JoinUs-descriptionText {
  font-size: 16px;
}
.JoinUs-descriptionLink {
  display: block;
  font-family: FFMarkWebPro, sans-serif;
  font-size: 18px;
  color: #ff3333;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: -1px;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  margin-top: 50px;
}
.isMobile .JoinUs-descriptionLink {
  font-size: 16px;
  margin-top: 38px;
}
.JoinUs-descriptionLink:hover {
  text-decoration: underline;
}
