.FooterNav {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.isMobile .FooterNav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.FooterNav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  width: 33.33333333%;
  overflow: hidden;
  text-decoration: none;
  background-color: #383633;
}
.isMobile .FooterNav-item {
  width: 100%;
}
.FooterNav-itemContent {
  margin-bottom: 40px;
  margin-top: 40px;
  border-right: 1px solid #4c4a48;
}
.isMobile .FooterNav-itemContent {
  border-right: none;
  border-bottom: 1px solid #4c4a48;
  margin-bottom: 0;
}
.FooterNav-item:hover img {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.FooterNav-item:hover .FooterNav-arrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.FooterNav-itemImage {
  margin: 0 auto;
  width: 80%;
  position: relative;
  padding-bottom: 52.8%;
  overflow: hidden;
}
.isMobile .FooterNav-itemImage {
  width: auto;
  height: auto;
  padding: 0 20px;
}
.FooterNav-itemImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  -webkit-transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out;
  transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .FooterNav-itemImage img {
  position: relative;
}
.FooterNav-itemInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  margin: 50px auto 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .FooterNav-itemInfo {
  margin-top: 20px;
  margin-left: 20px;
}
.FooterNav-itemInfoTitle {
  font-size: 36px;
  line-height: 40px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #383633;
  letter-spacing: 0em;
  color: #ffffff;
  font-weight: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media print {
  .FooterNav-itemInfoTitle {
    color: #000000;
  }
}
.isMobile .FooterNav-itemInfoTitle {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.isMobile .FooterNav-itemInfoTitle br {
  display: none;
}
.FooterNav-itemInfoDescr {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  letter-spacing: 0em;
  color: #808080;
  font-weight: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 45px;
}
.isMobile .FooterNav-itemInfoDescr {
  font-size: 16px;
  line-height: 25px;
}
.isMobile .FooterNav-itemInfoDescr {
  margin-top: 16px;
}
.FooterNav-arrow {
  width: 69px;
  cursor: pointer;
  overflow: hidden;
  width: 60px;
  height: 32px;
  margin-left: 40px;
  margin-bottom: 20px;
}
.isDesktop .FooterNav-arrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .FooterNav-arrow {
  width: 48px;
  height: 26px;
}
.FooterNav-arrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .FooterNav-arrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .FooterNav-arrow {
  width: 30px;
  height: 20px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.FooterNav-arrow svg {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}
