.SearchIndustries {
  background-color: #ffffff;
  padding: 86px 100px 117px 100px;
}
.isMobile .SearchIndustries {
  padding: 39px 0 60px;
}
.SearchIndustries-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
}
.isMobile .SearchIndustries-title {
  font-size: 36px;
  line-height: 40px;
}
.SearchIndustries-description {
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #383633;
  margin-top: 45px;
  line-height: 1.111;
}
.isMobile .SearchIndustries-description {
  font-size: 24px;
  margin-top: 30px;
  line-height: 30px;
}
.SearchIndustries-content {
  margin-top: 76px;
}
.isMobile .SearchIndustries-content {
  margin-top: 20px;
}
