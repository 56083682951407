@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_6_0.eot');
  src: url('/static/fonts/391EB3_6_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_6_0.woff2') format('woff2'), url('/static/fonts/391EB3_6_0.woff') format('woff'), url('/static/fonts/391EB3_6_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_9_0.eot');
  src: url('/static/fonts/391EB3_9_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_9_0.woff2') format('woff2'), url('/static/fonts/391EB3_9_0.woff') format('woff'), url('/static/fonts/391EB3_9_0.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_7_0.eot');
  src: url('/static/fonts/391EB3_7_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_7_0.woff2') format('woff2'), url('/static/fonts/391EB3_7_0.woff') format('woff'), url('/static/fonts/391EB3_7_0.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_8_0.eot');
  src: url('/static/fonts/391EB3_8_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_8_0.woff2') format('woff2'), url('/static/fonts/391EB3_8_0.woff') format('woff'), url('/static/fonts/391EB3_8_0.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_A_0.eot');
  src: url('/static/fonts/391EB3_A_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_A_0.woff2') format('woff2'), url('/static/fonts/391EB3_A_0.woff') format('woff'), url('/static/fonts/391EB3_A_0.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_B_0.eot');
  src: url('/static/fonts/391EB3_B_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_B_0.woff2') format('woff2'), url('/static/fonts/391EB3_B_0.woff') format('woff'), url('/static/fonts/391EB3_B_0.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_C_0.eot');
  src: url('/static/fonts/391EB3_C_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_C_0.woff2') format('woff2'), url('/static/fonts/391EB3_C_0.woff') format('woff'), url('/static/fonts/391EB3_C_0.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "FFMarkWebPro";
  src: url('/static/fonts/391EB3_D_0.eot');
  src: url('/static/fonts/391EB3_D_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_D_0.woff2') format('woff2'), url('/static/fonts/391EB3_D_0.woff') format('woff'), url('/static/fonts/391EB3_D_0.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "CharterITCStd";
  src: url('/static/fonts/391EB3_2_0.eot');
  src: url('/static/fonts/391EB3_2_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_2_0.woff2') format('woff2'), url('/static/fonts/391EB3_2_0.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CharterITCStd";
  src: url('/static/fonts/391EB3_3_0.eot');
  src: url('/static/fonts/391EB3_3_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_3_0.woff2') format('woff2'), url('/static/fonts/391EB3_3_0.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "CharterITCStd";
  src: url('/static/fonts/391EB3_1_0.eot');
  src: url('/static/fonts/391EB3_1_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_1_0.woff2') format('woff2'), url('/static/fonts/391EB3_1_0.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "CharterITCStd";
  src: url('/static/fonts/391EB3_5_0.eot');
  src: url('/static/fonts/391EB3_5_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_5_0.woff2') format('woff2'), url('/static/fonts/391EB3_5_0.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "CharterITCStd";
  src: url('/static/fonts/391EB3_4_0.eot');
  src: url('/static/fonts/391EB3_4_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_4_0.woff2') format('woff2'), url('/static/fonts/391EB3_4_0.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "CharterITCStd";
  src: url('/static/fonts/391EB3_0_0.eot');
  src: url('/static/fonts/391EB3_0_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/391EB3_0_0.woff2') format('woff2'), url('/static/fonts/391EB3_0_0.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
