.SideNav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 20;
  pointer-events: none;
}
.isMobile .SideNav {
  display: none;
  visibility: hidden;
}
.SideNav--visible .SideNav-links {
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.2s opacity ease-out, 0s visibility 0s ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0s visibility 0s ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out, 0s visibility 0s ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out, 0s visibility 0s ease-out, 0.2s -webkit-transform ease-out;
}
.SideNav--visible .SideNav-overlay {
  opacity: 1;
}
.SideNav-wrapper {
  position: relative;
  width: 580px;
  height: 100%;
}
.SideNav-wrapperClose {
  position: absolute;
  right: 0;
  width: calc(100% - 580px);
  top: 0;
  bottom: 0;
}
.SideNav-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
}
.TruePage .SideNav-overlay,
.true .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #d6d2c8), to(rgba(214, 210, 200, 0)));
  background: linear-gradient(to right, #d6d2c8 75%, rgba(214, 210, 200, 0) 100%);
}
.SearchPage .SideNav-overlay,
.search .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #b4463d), to(rgba(180, 70, 61, 0)));
  background: linear-gradient(to right, #b4463d 75%, rgba(180, 70, 61, 0) 100%);
}
.TechPage .SideNav-overlay,
.tech .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #51a29e), to(rgba(81, 162, 158, 0)));
  background: linear-gradient(to right, #51a29e 75%, rgba(81, 162, 158, 0) 100%);
}
.DevPage .SideNav-overlay,
.dev .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #142f81), to(rgba(20, 47, 129, 0)));
  background: linear-gradient(to right, #142f81 75%, rgba(20, 47, 129, 0) 100%);
}
.true.isHover .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #d6d2c8), to(rgba(214, 210, 200, 0))) !important;
  background: linear-gradient(to right, #d6d2c8 75%, rgba(214, 210, 200, 0) 100%) !important;
}
.search.isHover .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #b4463d), to(rgba(180, 70, 61, 0))) !important;
  background: linear-gradient(to right, #b4463d 75%, rgba(180, 70, 61, 0) 100%) !important;
}
.tech.isHover .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #51a29e), to(rgba(81, 162, 158, 0))) !important;
  background: linear-gradient(to right, #51a29e 75%, rgba(81, 162, 158, 0) 100%) !important;
}
.dev.isHover .SideNav-overlay {
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #142f81), to(rgba(20, 47, 129, 0))) !important;
  background: linear-gradient(to right, #142f81 75%, rgba(20, 47, 129, 0) 100%) !important;
}
.SideNav-links {
  position: absolute;
  top: 50%;
  left: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-transform: translate3d(-80px, -50%, 0);
          transform: translate3d(-80px, -50%, 0);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s opacity ease-out, 0s visibility 0.2s ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0s visibility 0.2s ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out, 0s visibility 0.2s ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out, 0s visibility 0.2s ease-out, 0.2s -webkit-transform ease-out;
}
.SideNav-link {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5em;
  line-height: 65px;
  text-decoration: none;
  pointer-events: all;
}
.isMobile .SideNav-link {
  font-size: 36px;
  line-height: 40px;
}
.SearchPage .SideNav-link,
.TechPage .SideNav-link,
.DevPage .SideNav-link {
  color: #ffffff;
}
.SideNav-link:first-child {
  margin-top: 0;
}
.SideNav-link.isActive,
.TruePage .SideNav-link[data-id="true"] {
  color: transparent;
  -webkit-text-stroke: 1px #383633;
  -moz-text-stroke: 1px #383633;
  text-stroke: 1px #383633;
  border-color: #383633;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  cursor: pointer;
}
.SearchPage .SideNav-link[data-id="search"],
.TechPage .SideNav-link[data-id="tech"],
.DevPage .SideNav-link[data-id="dev"] {
  color: transparent;
  -webkit-text-stroke: 1px #ffffff;
  -moz-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  border-color: #ffffff;
  cursor: pointer;
}
.SideNav-link.isHover {
  text-decoration: underline;
}
.isHover .SideNav-link {
  color: #ffffff;
}
.TruePage .isHover .SideNav-link[data-id="true"] {
  color: transparent;
  -webkit-text-stroke: 1px #ffffff;
  -moz-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  border-color: #ffffff;
  cursor: pointer;
}
.TruePage .isHover .SideNav-link[data-id="true"].isHover {
  color: transparent;
  -webkit-text-stroke: 1px #383633;
  -moz-text-stroke: 1px #383633;
  text-stroke: 1px #383633;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  border-color: #383633;
  cursor: pointer;
}
.true.isHover .SideNav-link {
  color: #383633;
}
.SearchPage .true.isHover .SideNav-link[data-id="search"],
.TechPage .true.isHover .SideNav-link[data-id="tech"],
.DevPage .true.isHover .SideNav-link[data-id="dev"] {
  color: transparent;
  -webkit-text-stroke: 1px #383633;
  -moz-text-stroke: 1px #383633;
  text-stroke: 1px #383633;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  cursor: pointer;
  border-color: #383633;
}
