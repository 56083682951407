.SearchOfferPassword {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.SearchOfferPassword-outter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.SearchOfferPassword-img {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 50%;
  height: 100%;
}
.SearchOfferPassword-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.SearchOfferPassword-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50%;
  height: 100%;
  background: #ffffff;
}
.isMobile .SearchOfferPassword-wrapper {
  width: 100%;
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
}
.SearchOfferPassword-message {
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  margin-top: 1px;
  letter-spacing: -0.05em;
  color: #f5371f;
  width: 100%;
  text-align: center;
  display: none;
}
.invalid .SearchOfferPassword-message {
  display: block;
}
.isMobile .SearchOfferPassword-message {
  font-size: 14px;
}
.SearchOfferPassword-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 62.33766234%;
}
.SearchOfferPassword-infoText {
  font-size: 18px;
  line-height: 25px;
  font-family: "FFMarkWebPro";
  font-weight: 700;
  color: #383633;
  letter-spacing: 0em;
  font-weight: 500;
  color: #808080;
  margin-bottom: 65px;
  text-align: center;
}
.SearchOfferPassword-infoInput {
  width: 100%;
}
.SearchOfferPassword-infoInput input {
  font-size: 48px;
  line-height: 40px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  width: 100%;
  text-align: center;
  background: none;
  outline: none;
  border: none;
  padding: 10px;
  border-bottom: 2px solid #383633;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.SearchOfferPassword-infoInput input::-webkit-input-placeholder {
  color: #383633;
}
.SearchOfferPassword-infoInput input::-moz-placeholder {
  color: #383633;
}
.SearchOfferPassword-infoInput input:-moz-placeholder {
  color: #383633;
}
.SearchOfferPassword-infoInput input:-ms-input-placeholder {
  color: #383633;
}
.isMobile .SearchOfferPassword-infoInput input {
  font-size: 36px;
}
.SearchOfferPassword-infoBtn {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin-top: 70px;
  background: #f5371f;
  text-transform: lowercase;
  padding: 11px 62px 14px;
  outline: none;
  border: none;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
  cursor: pointer;
}
.isMobile .SearchOfferPassword-infoBtn {
  font-size: 16px;
  line-height: 20px;
}
.SearchOfferPassword-infoBtn:hover {
  opacity: 0.7;
}
.SearchOfferPassword-infoBtn.disabled {
  background-color: #e5e5e5;
  color: #ffffff;
  cursor: default;
  pointer-events: none;
}
.SearchOfferSection.IsVisPopup {
  height: 100vh;
  overflow: hidden;
}
.SearchOfferSection-header {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 43px 60px 35px;
  background: #f4f3f1;
}
@media print {
  .SearchOfferSection-header {
    background: #ffffff;
    padding: 1.5625vw 4.6875vw;
  }
}
.SearchOfferSection-headerPrint {
  display: none;
}
@media print {
  .SearchOfferSection-headerPrint {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 3.125vw 0 1.5625vw;
    margin: 0 4.6875vw;
    border-bottom: 1px solid #000000;
  }
}
.SearchOfferSection-headerPrintTrue {
  font-family: "FFMarkWebPro";
  font-size: 3.75vw;
  color: #f5371f;
  letter-spacing: -0.05em;
  font-weight: 500;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .SearchOfferSection-header {
  padding: 0;
  background: none;
}
.SearchOfferSection-headerTitle {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .SearchOfferSection-headerTitle {
  font-size: 36px;
  line-height: 40px;
}
@media print {
  .SearchOfferSection-headerTitle {
    font-size: 4.6875vw;
    color: #000000;
  }
}
.isMobile .SearchOfferSection-headerTitle {
  padding: 27px 20px 34px;
  background: #f4f3f1;
}
.SearchOfferSection-headerTitlePrint {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #f5371f;
  margin-top: 20px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .SearchOfferSection-headerTitlePrint {
  font-size: 16px;
  line-height: 20px;
}
@media print {
  .SearchOfferSection-headerTitlePrint {
    display: none;
  }
}
.isDesktop .SearchOfferSection-headerTitlePrint:hover {
  text-decoration: underline;
}
.isMobile .SearchOfferSection-headerTitlePrint {
  display: none;
}
.SearchOfferSection-headerTitlePrint svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  fill: #f5371f;
  margin-right: 5px;
  width: 24px;
  height: 18px;
}
.SearchOfferSection-headerInfo {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 35px;
  padding-top: 20px;
  border-top: 1px solid #d8d8d8;
}
@media print {
  .SearchOfferSection-headerInfo {
    border-top-color: #000000;
    border-bottom: 1px solid #000000;
    margin-top: 2.34375vw;
    padding-bottom: 1.171875vw;
  }
}
.isMobile .SearchOfferSection-headerInfo {
  border-top: none;
  margin-top: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 25px 20px 0px;
}
.SearchOfferSection-headerInfoTextWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
@media print {
  .SearchOfferSection-headerInfoTextWrapper {
    margin: 0 -30px;
    width: auto;
  }
}
.isMobile .SearchOfferSection-headerInfoTextWrapper {
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: block;
}
.SearchOfferSection-headerInfoBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 30.08064516%;
}
@media print {
  .SearchOfferSection-headerInfoBlock {
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin: 0 30px;
  }
}
.isMobile .SearchOfferSection-headerInfoBlock {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d8;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.SearchOfferSection-headerInfoBlock--logo {
  width: 22.58064516%;
}
@media print {
  .SearchOfferSection-headerInfoBlock--logo {
    display: none;
  }
}
.isMobile .SearchOfferSection-headerInfoBlock--logo {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 30px;
}
.SearchOfferSection-headerInfoBlock--logo .SearchOfferSection-headerInfoBlockImg {
  width: 64.28571429%;
  position: relative;
}
.SearchOfferSection-headerInfoBlock--logo .SearchOfferSection-headerInfoBlockImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 58.33333333%;
}
@media print {
  .SearchOfferSection-headerInfoBlock--logo .SearchOfferSection-headerInfoBlockImg {
    width: 18%;
  }
}
.SearchOfferSection-headerInfoBlock--logo .SearchOfferSection-headerInfoBlockImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: contain; object-position: center';
  mix-blend-mode: multiply;
  margin: 0 auto;
}
.SearchOfferSection-headerInfoBlock--socMob {
  display: none;
}
.isMobile .SearchOfferSection-headerInfoBlock--socMob {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .SearchOfferSection-headerInfoBlock--socials {
  display: none;
}
.SearchOfferSection-headerInfoBlockTitle {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
}
.isMobile .SearchOfferSection-headerInfoBlockTitle {
  font-size: 16px;
  line-height: 20px;
}
@media print {
  .SearchOfferSection-headerInfoBlockTitle {
    color: #808080;
    font-size: 16px;
  }
}
.isMobile .SearchOfferSection-headerInfoBlockTitle {
  width: 110px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 110px;
          flex: 0 0 110px;
  padding-right: 5px;
  color: #808080;
}
.SearchOfferSection-headerInfoBlockBody {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  letter-spacing: -0.05em;
  color: #383633;
  width: 100%;
}
.isMobile .SearchOfferSection-headerInfoBlockBody {
  font-size: 16px;
  line-height: 20px;
}
@media print {
  .SearchOfferSection-headerInfoBlockBody {
    color: #000000;
    font-size: 16px;
  }
}
@media print {
  .SearchOfferSection-headerInfoBlockBody--social {
    display: none;
  }
}
.SearchOfferSection-headerInfoBlockBody--socialPrint {
  display: none;
}
@media print {
  .SearchOfferSection-headerInfoBlockBody--socialPrint {
    display: block;
    font-family: "FFMarkWebPro";
    font-weight: 500;
    font-size: 16px;
  }
  .SearchOfferSection-headerInfoBlockBody--socialPrint span {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
.SearchOfferSection-headerInfoBlockBody a {
  color: #f5371f;
  text-decoration: none;
}
@media print {
  .SearchOfferSection-headerInfoBlockBody a {
    color: #000000;
  }
}
.isDesktop .SearchOfferSection-headerInfoBlockBody a:hover {
  text-decoration: underline;
}
.SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody,
.SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 9px;
}
.isMobile .SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody,
.isMobile .SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0;
}
.SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody a,
.SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody a {
  text-decoration: none !important;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 10.71428571%;
  width: 7.14285714%;
  position: relative;
}
.SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody a:before,
.SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody a:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.isMobile .SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody a,
.isMobile .SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 9.375%;
  width: 6.25%;
}
.SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody a svg,
.SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody a svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  fill: #383633;
}
.isDesktop .SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody a svg:hover,
.isDesktop .SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody a svg:hover {
  fill: #f5371f;
}
.SearchOfferSection-headerInfoBlock--socials .SearchOfferSection-headerInfoBlockBody a:first-child,
.SearchOfferSection-headerInfoBlock--socMob .SearchOfferSection-headerInfoBlockBody a:first-child {
  margin-left: 0;
}
.SearchOfferSection-body {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 80px 60px 120px;
}
@media print {
  .SearchOfferSection-body {
    padding: 40px 4.6875vw 9.375vw;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.isMobile .SearchOfferSection-body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0px 20px 0;
}
.SearchOfferSection-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 22.58064516%;
}
.isMobile .SearchOfferSection-sidebar {
  width: 100%;
}
.SearchOfferSection-sidebarTeam {
  width: 80.35714286%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .SearchOfferSection-sidebarTeam {
  width: 100%;
}
.isMobile .SearchOfferSection-sidebarTeamSearch {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d8;
}
.SearchOfferSection-sidebarTeamInvestors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-bottom: 47px;
}
.isMobile .SearchOfferSection-sidebarTeamInvestors {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 0;
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d8;
}
.SearchOfferSection-sidebarTeamInvestorsTitle {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #808080;
}
.isMobile .SearchOfferSection-sidebarTeamInvestorsTitle {
  font-size: 16px;
  line-height: 20px;
}
@media print {
  .SearchOfferSection-sidebarTeamInvestorsTitle {
    font-size: 16px;
  }
}
.isMobile .SearchOfferSection-sidebarTeamInvestorsTitle {
  width: 110px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 110px;
          flex: 0 0 110px;
  padding-right: 5px;
}
.SearchOfferSection-sidebarTeamInvestorsBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SearchOfferSection-sidebarTeamInvestorsBody a,
.SearchOfferSection-sidebarTeamInvestorsBody span {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  font-weight: normal;
}
.isMobile .SearchOfferSection-sidebarTeamInvestorsBody a,
.isMobile .SearchOfferSection-sidebarTeamInvestorsBody span {
  font-size: 16px;
  line-height: 25px;
}
@media print {
  .SearchOfferSection-sidebarTeamInvestorsBody a,
  .SearchOfferSection-sidebarTeamInvestorsBody span {
    font-size: 16px;
  }
}
.SearchOfferSection-sidebarTeamInvestorsBody span {
  color: #383633;
}
@media print {
  .SearchOfferSection-sidebarTeamInvestorsBody span {
    color: #000000;
    font-size: 16px;
  }
}
.SearchOfferSection-sidebarTeamInvestorsBody a {
  color: #f5371f;
}
@media print {
  .SearchOfferSection-sidebarTeamInvestorsBody a {
    color: #000000;
    font-size: 16px;
  }
}
.isDesktop .SearchOfferSection-sidebarTeamInvestorsBody a:hover {
  text-decoration: underline;
}
.SearchOfferSection-sidebarTeamTitle {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #808080;
}
.isMobile .SearchOfferSection-sidebarTeamTitle {
  font-size: 16px;
  line-height: 20px;
}
@media print {
  .SearchOfferSection-sidebarTeamTitle {
    font-size: 16px;
  }
}
.isMobile .SearchOfferSection-sidebarTeamTitle {
  width: 110px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 110px;
          flex: 0 0 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 5px;
}
.SearchOfferSection-sidebarTeamList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SearchOfferSection-sidebarTeamItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 47px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media print {
  .SearchOfferSection-sidebarTeamItem {
    margin-top: 20px;
  }
}
.isMobile .SearchOfferSection-sidebarTeamItem {
  margin-top: 40px;
}
.isMobile .SearchOfferSection-sidebarTeamItem:first-child {
  margin-top: 0;
}
.SearchOfferSection-sidebarTeamItemName {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  font-weight: 700;
  color: #383633;
}
.isMobile .SearchOfferSection-sidebarTeamItemName {
  font-size: 16px;
  line-height: 25px;
}
@media print {
  .SearchOfferSection-sidebarTeamItemName {
    color: #000000;
    font-size: 16px;
  }
}
.SearchOfferSection-sidebarTeamItemPosition {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #383633;
  font-weight: normal;
}
.isMobile .SearchOfferSection-sidebarTeamItemPosition {
  font-size: 16px;
  line-height: 25px;
}
@media print {
  .SearchOfferSection-sidebarTeamItemPosition {
    color: #000000;
    font-size: 16px;
  }
}
.SearchOfferSection-sidebarTeamItemEmail {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #f5371f;
  text-decoration: none;
  font-weight: normal;
}
.isMobile .SearchOfferSection-sidebarTeamItemEmail {
  font-size: 16px;
  line-height: 25px;
}
@media print {
  .SearchOfferSection-sidebarTeamItemEmail {
    color: #000000;
    font-size: 16px;
  }
}
.isDesktop .SearchOfferSection-sidebarTeamItemEmail:hover {
  text-decoration: underline;
}
.SearchOfferSection-sidebarTeamItemPhone {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #f5371f;
  text-decoration: none;
  font-weight: normal;
}
.isMobile .SearchOfferSection-sidebarTeamItemPhone {
  font-size: 16px;
  line-height: 25px;
}
@media print {
  .SearchOfferSection-sidebarTeamItemPhone {
    color: #000000;
    font-size: 16px;
  }
}
.isDesktop .SearchOfferSection-sidebarTeamItemPhone:hover {
  text-decoration: underline;
}
.SearchOfferSection-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 58.06451613%;
  margin-left: 3.22580645%;
}
@media print {
  .SearchOfferSection-content {
    width: auto;
    margin-left: 8.06451613%;
  }
}
.isMobile .SearchOfferSection-content {
  margin-left: 0;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 65px;
}
.SearchOfferSection-blocks {
  position: relative;
  width: 100%;
  min-height: 300px;
}
.SearchOfferSection-blocksBlock:first-child .CommonBlock {
  margin-top: 0;
}
