.WhoWeAre {
  background-color: #ffffff;
  padding: 86px 100px 117px 100px;
}
.isMobile .WhoWeAre {
  padding: 39px 0 60px;
}
.WhoWeAre-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  max-width: 800px;
}
.isMobile .WhoWeAre-title {
  font-size: 36px;
  line-height: 40px;
}
.WhoWeAre-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 65px;
  font-family: "CharterITCStd";
}
.isMobile .WhoWeAre-content {
  display: block;
  margin-top: 0;
}
.WhoWeAre-description {
  width: 100%;
  max-width: 240px;
  font-size: 18px;
  color: #808080;
  line-height: 1.667;
  margin-top: 30px;
}
.isMobile .WhoWeAre-description {
  margin-top: 49px;
  font-size: 16px;
  max-width: none;
}
.WhoWeAre-whatWeLookList {
  width: 100%;
  margin-left: 80px;
  padding-top: 18px;
}
.isMobile .WhoWeAre-whatWeLookList {
  padding-top: 58px;
  margin-left: 0;
}
.WhoWeAre-whatWeLookItem {
  margin-bottom: 41px;
}
.isMobile .WhoWeAre-whatWeLookItem {
  margin-bottom: 40px;
}
.WhoWeAre-whatWeLookItemTitle {
  font-family: CharterITCStd, sans-serif;
  font-size: 36px;
  color: #333333;
}
.isMobile .WhoWeAre-whatWeLookItemTitle {
  font-size: 24px;
}
.WhoWeAre-whatWeLookItemDescription {
  font-family: CharterITCStd, sans-serif;
  font-size: 18px;
  color: #333333;
  margin-top: 12px;
}
.isMobile .WhoWeAre-whatWeLookItemDescription {
  font-size: 16px;
  margin-top: 5px;
}
