.IndexOurStory {
  background-color: #f5371f;
  padding: 86px 0px 80px;
}
.isMobile .IndexOurStory {
  padding: 38px 0 80px;
}
.IndexOurStory-wrapper {
  width: 100%;
}
.IndexOurStory-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  font-weight: 500;
  margin-left: -4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .IndexOurStory-title {
  font-size: 36px;
  line-height: 40px;
}
.IndexOurStory-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 77px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-family: "CharterITCStd";
}
.isMobile .IndexOurStory-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 33px;
}
.IndexOurStory-description {
  font-size: 36px;
  line-height: 40px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #383633;
  letter-spacing: 0em;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  font-weight: normal;
  color: #ffffff;
}
@media print {
  .IndexOurStory-description {
    color: #000000;
  }
}
.isMobile .IndexOurStory-description {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.025em;
}
.isMobile .IndexOurStory-description {
  width: 100%;
  padding-right: 0;
}
.IndexOurStory-info {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .IndexOurStory-info {
  width: 100%;
  padding-left: 0;
  margin-top: 38px;
}
.IndexOurStory-infoText {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #383633;
  font-weight: normal;
}
.isMobile .IndexOurStory-infoText {
  font-size: 16px;
  line-height: 25px;
}
.IndexOurStory-infoText b {
  width: 100%;
  display: block;
}
.IndexOurStory-more {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #ffffff;
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -0.9px;
  margin-top: 55px;
}
.isMobile .IndexOurStory-more {
  font-size: 16px;
  line-height: 20px;
}
.isMobile .IndexOurStory-more {
  margin-top: 45px;
  line-height: 25px;
}
.IndexOurStory-more:hover {
  text-decoration: underline;
}
