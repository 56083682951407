.LogosRows-row {
  width: 100%;
  margin-bottom: 60px;
}
.LogosRows-rowInner {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.LogosRows-rowItem {
  width: 180px;
  padding-top: 80px;
  height: 0;
  margin-right: 25px;
  margin-left: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.isMobile .LogosRows-rowItem {
  width: 100px;
  margin-right: 17px;
  margin-left: 17px;
  padding-top: 44.5px;
}
.LogosRows-rowItem img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  opacity: 0.2;
}
