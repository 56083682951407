.CommonBlockVideo {
  position: relative;
}
.isMobile .CommonBlockVideo {
  min-width: 100%;
}
.CommonBlockVideo.isInitialState .CommonBlockVideo-video {
  opacity: 0;
  -webkit-transform: translateY(100px);
      -ms-transform: translateY(100px);
          transform: translateY(100px);
}
.CommonBlockVideo-video {
  width: 100%;
  z-index: 1;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: 0.2s opaicty ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opaicty ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opaicty ease-out, 0.2s transform ease-out;
  transition: 0.2s opaicty ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.isDesktop .CommonBlockVideo-video:hover .CommonBlockVideo-videoPlayBtn {
  -webkit-transform: translate(-50%, -50%) scale(1.1, 1.1);
      -ms-transform: translate(-50%, -50%) scale(1.1, 1.1);
          transform: translate(-50%, -50%) scale(1.1, 1.1);
}
.CommonBlockVideo-videoPreview {
  width: 100%;
  height: 100%;
}
.CommonBlockVideo-videoPreviewWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  cursor: pointer;
}
.CommonBlockVideo-videoPreview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  pointer-events: none;
}
.CommonBlockVideo-videoPlayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  -webkit-transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out;
  transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .CommonBlockVideo-videoPlayBtn {
  width: 54px;
  height: 54px;
}
.CommonBlockVideo-videoPlayBtn svg {
  width: 100%;
  height: 100%;
}
.CommonBlockVideo-iframe {
  z-index: 2;
}
.isMobile .CommonBlockVideo-iframe:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.isPhone .CommonBlockVideo-iframe:before {
  height: 73.5%;
}
.isPhone .CommonBlockVideo-iframe:after {
  content: '';
  position: absolute;
  left: 17%;
  right: 17%;
  bottom: 0;
  height: 26.5%;
  z-index: 1;
}
.isTablet .CommonBlockVideo-iframe:before {
  height: 87%;
}
.CommonBlockVideo-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.CommonBlockVideo-iframe img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.CommonBlockVideo-iframe img {
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}
