.NotFound {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 5;
}
.NotFound-wrapper {
  position: absolute;
  bottom: 110px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 63.88888889%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.isMobile .NotFound-wrapper {
  width: 100%;
  bottom: 0;
  padding: 0 20px 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(80%, #d6d2c8), to(rgba(214, 210, 200, 0)));
  background: linear-gradient(to top, #d6d2c8 80%, rgba(214, 210, 200, 0) 100%);
}
.NotFound-title {
  font-size: 48px;
  line-height: 40px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: calc(50% - 20px);
}
.isMobile .NotFound-title {
  font-size: 36px;
  width: 100%;
}
.NotFound-title span {
  display: block;
  width: 100%;
  color: #f5371f;
}
.NotFound-descr {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  font-weight: normal;
  color: #383633;
  width: calc(50% - 20px);
  margin-left: 40px;
  padding-top: 10px;
}
.isMobile .NotFound-descr {
  font-size: 16px;
  line-height: 25px;
}
.isMobile .NotFound-descr {
  margin-top: 40px;
  margin-left: 0;
  padding-top: 0;
  width: 100%;
  padding-right: 15px;
}
.isMobile .NotFound-descr br {
  display: none;
}
.NotFound-descr a {
  color: #f5371f;
  text-decoration: none;
}
.isDesktop .NotFound-descr a:hover {
  text-decoration: underline;
}
