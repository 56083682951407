.IndexNews {
  background-color: #ffffff;
  padding: 86px 0 90px;
}
.isMobile .IndexNews {
  padding: 38px 0 80px;
}
.IndexNews.dark {
  background-color: #333333;
}
.SearchPage .IndexNews,
.DevPage .IndexNews,
.TechPage .IndexNews {
  background-color: #e5e4df;
}
.IndexNews-wrapper {
  width: 100%;
}
.IndexNews-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #333333;
  margin-left: -4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .IndexNews-title {
  font-size: 36px;
  line-height: 40px;
}
.dark .IndexNews-title {
  color: #ffffff;
}
.IndexNews-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  font-family: "CharterITCStd";
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.IndexNews-news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-decoration: none;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.IndexNews-news:hover .IndexNews-newsInfoArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.IndexNews-news:hover .IndexNews-newsInfoTitle {
  color: #f5371f;
}
.DevPage .IndexNews-news:hover .IndexNews-newsInfoTitle {
  color: #3b6dca;
}
.TechPage .IndexNews-news:hover .IndexNews-newsInfoTitle {
  color: #3cbea6;
}
.IndexNews-newsDescr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.IndexNews-newsDescrTag {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #808080;
  margin-right: 30px;
}
.isMobile .IndexNews-newsDescrTag {
  font-size: 16px;
  line-height: 20px;
}
.IndexNews-newsDescrDate {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  letter-spacing: 0em;
  color: #808080;
  line-height: 36px;
}
.isMobile .IndexNews-newsDescrDate {
  font-size: 16px;
  line-height: 25px;
}
.IndexNews-newsInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 3px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .IndexNews-newsInfo {
  margin-top: 1px;
}
.IndexNews-newsInfoTitle {
  font-size: 48px;
  line-height: 60px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.025em;
  width: 82.60869565%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
}
.isMobile .IndexNews-newsInfoTitle {
  font-size: 24px;
  line-height: 30px;
}
.isMobile .IndexNews-newsInfoTitle {
  width: 83.33333333%;
}
.IndexNews-newsInfoArrow {
  width: 69px;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 32px;
  position: relative;
}
.isDesktop .IndexNews-newsInfoArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .IndexNews-newsInfoArrow {
  width: 48px;
  height: 26px;
}
.IndexNews-newsInfoArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .IndexNews-newsInfoArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .IndexNews-newsInfoArrow {
  width: 30px;
  height: 20px;
}
.IndexNews-newsInfoArrow svg {
  width: 100%;
  height: 100%;
  fill: #ff3333;
}
.DevPage .IndexNews-newsInfoArrow svg {
  fill: #3b6dca;
}
.TechPage .IndexNews-newsInfoArrow svg {
  fill: #3cbea6;
}
.IndexNews-link {
  color: #f5371f;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  margin-top: 20px;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -0.9px;
}
.DevPage .IndexNews-link {
  color: #3b6dca;
}
.TechPage .IndexNews-link {
  color: #3cbea6;
}
.isMobile .IndexNews-link {
  margin-top: 55px;
}
.IndexNews-link:hover {
  text-decoration: underline;
}
