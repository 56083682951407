.SearchUniversalBlock {
  background-color: #ffffff;
  padding: 45px 0 47px;
  border-bottom: 1px solid #d8d8d8;
}
.SearchUniversalBlock:last-of-type {
  border-bottom: none;
}
.SearchUniversalBlock-title {
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #383633;
}
.isMobile .SearchUniversalBlock-title {
  font-size: 24px;
}
.SearchUniversalBlock-content {
  display: block;
}
.SearchUniversalBlock-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .SearchUniversalBlock-line {
  display: block;
}
.SearchUniversalBlock-lineCenterAlign {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.SearchUniversalBlock-leftSide {
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
  position: relative;
}
.isMobile .SearchUniversalBlock-leftSide {
  margin-bottom: 50px;
}
.SearchUniversalBlock-rightSide {
  -ms-flex-preferred-size: 66%;
      flex-basis: 66%;
  position: relative;
}
.SearchUniversalBlock-description {
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #808080;
  line-height: 1.667;
  margin-bottom: 60px;
  margin-top: 12px;
}
.isMobile .SearchUniversalBlock-description {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 35px;
}
.SearchUniversalBlock-industries {
  font-family: "CharterITCStd";
  font-size: 17px;
  color: #808080;
  margin-top: 35px;
  margin-bottom: 32px;
}
.isMobile .SearchUniversalBlock-industries {
  font-size: 16px;
}
.SearchUniversalBlock-industry,
.SearchUniversalBlock-region {
  margin-bottom: 9px;
  margin-top: 9px;
  padding-left: 20px;
}
.SearchUniversalBlock-industry:before,
.SearchUniversalBlock-region:before {
  content: '•';
  font-size: 17px;
  position: absolute;
  left: 0;
}
.SearchUniversalBlock-region {
  position: relative;
}
.SearchUniversalBlock-region:before {
  top: 6px;
  color: #f5371f;
}
.SearchUniversalBlock-featuredClients {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isMobile .SearchUniversalBlock-featuredClients {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SearchUniversalBlock-featuredClient {
  -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 8.9%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}
.SearchUniversalBlock-featuredClient a {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 10;
}
.isMobile .SearchUniversalBlock-featuredClient {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 43%;
          flex: 0 0 43%;
  padding-bottom: 19.1%;
  margin-bottom: 70px;
  margin-right: 0;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.isMobile .SearchUniversalBlock-featuredClient:nth-child(2n) {
  margin-right: 0;
}
.SearchUniversalBlock-featuredClient:last-child {
  margin-right: 0;
}
.SearchUniversalBlock-featuredClient:first-child {
  margin-left: 0;
}
.SearchUniversalBlock-featuredClient img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.isMobile .SearchUniversalBlock-featuredClient img {
  top: auto;
  left: auto;
  height: 100%;
  width: auto;
}
.SearchUniversalBlock-viewTeam {
  margin-bottom: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.SearchUniversalBlock-viewSelected {
  text-decoration: none;
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isMobile .SearchUniversalBlock-viewSelected {
  font-size: 16px;
}
.SearchUniversalBlock-viewSelected:hover {
  text-decoration: underline;
}
.SearchUniversalBlock-viewSelected:visited {
  text-decoration: none;
}
.SearchUniversalBlock-viewSelectedMobile {
  display: none;
  width: 100%;
  text-align: center;
}
.isMobile .SearchUniversalBlock-viewSelectedMobile {
  display: block;
}
