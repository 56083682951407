.u-Clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.u-Btn {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: inherit;
  outline: none;
  cursor: pointer;
}
.u-Btn--disabled {
  pointer-events: none;
  cursor: default;
}
.u-Link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.u-Link--underline {
  text-decoration: underline;
}
.u-Link--underline:hover {
  text-decoration: none;
}
.u-Grid:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.u-Col {
  float: left;
  height: 100%;
  height: inherit;
  min-height: 1px;
}
.u-InCol {
  display: inline-block;
  vertical-align: top;
}
.u-Table {
  display: table;
  height: 100%;
  width: 100%;
}
.u-Cell {
  display: table-cell;
  height: 100%;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.u-CenteredBlock:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.u-VerticallyCentered {
  display: inline-block;
  vertical-align: middle;
}
.u-ScreenCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.u-ScreenCenterX {
  position: absolute;
  left: 50%;
  min-width: 100%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.u-FocusPlaceholder:focus::-webkit-input-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus::-moz-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus:-ms-input-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus::-ms-input-placeholder {
  color: transparent;
}
.u-FocusPlaceholder:focus::placeholder {
  color: transparent;
}
.u-AbsoluteSvg svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.u-Absolute100 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
