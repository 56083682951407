.SearchExtraMenu {
  position: fixed;
  left: 100px;
  top: 50%;
  right: 0;
  width: 120px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 25;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}
@media (min-width: 1180px) and (max-width: 1380px) {
  .SearchExtraMenu {
    left: 50px;
  }
}
@media (max-width: 1179px) {
  .SearchExtraMenu {
    left: 40px;
  }
}
.isMobile .SearchExtraMenu {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.TruePage .SearchExtraMenu,
.SearchExtraPage .SearchExtraMenu,
.TechPage .SearchExtraMenu,
.DevPage .SearchExtraMenu {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.SearchExtraMenu.isHide {
  opacity: 0;
}
.SearchExtraMenu.isOpenMenu {
  visibility: hidden;
  opacity: 0;
}
.SearchExtraMenu-content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.SearchExtraMenu-item {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  color: #ffffff;
  padding: 15px 0;
  border-top: 1px solid #ffffff;
  width: 100%;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
}
.isMobile .SearchExtraMenu-item {
  font-size: 16px;
  line-height: 20px;
}
.SearchExtraMenu-item:first-child {
  padding-top: 0;
  border-top: 0;
}
.isDesktop .SearchExtraMenu-item:hover,
.SearchExtraMenu-item.isActive {
  color: #eae680;
}
.SearchExtraMenu-item.isActive {
  pointer-events: none;
  cursor: default;
}
