.Radio-label {
  font-weight: 600;
  font-size: 1rem;
}
.Radio .mdl-radio__outer-circle {
  border: 1px solid #d9d9d9 !important;
}
.Radio .mdl-radio__inner-circle {
  background: #000;
}
.Radio.green .mdl-radio__inner-circle {
  background: #36bd24;
}
.Radio.red .mdl-radio__inner-circle {
  background: #e82100;
}
.Radio-radioButton {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #808080;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.Radio-radioButtonWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Radio-radioButtonWrapper label {
  margin-left: 10px;
  padding-bottom: 1px;
  font-size: 16px;
  color: #383633;
  font-weight: 500;
  letter-spacing: -0.05em;
  font-family: "FFMarkWebPro";
}
.Radio-radioButtonWrapper input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  cursor: pointer;
}
.Radio-radioButtonWrapper input:checked + .Radio-radioButton:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #f5371f;
  border-radius: 50%;
}
