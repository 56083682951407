.TopNav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 80px;
  -webkit-transition: background 0.5s ease, -webkit-transform 0.5s ease;
  transition: background 0.5s ease, -webkit-transform 0.5s ease;
  transition: background 0.5s ease, transform 0.5s ease;
  transition: background 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  pointer-events: none;
  min-width: 1120px;
  z-index: 25;
}
.TopNav.isHidden {
  -webkit-transform: translate3d(0, -80px, 0);
          transform: translate3d(0, -80px, 0);
}
.TopNav.isOnBackground {
  background-color: #383633;
}
.TopNav.isOnBackground .TopNav-sideButtonLine {
  background-color: #ffffff;
}
.TopNav.isOnBackground .TopNav-sideButtonText {
  color: #ffffff;
}
.isMobile .TopNav {
  height: 60px;
  min-width: 100%;
}
.isDesktop .TopNav--sideTopNav .TopNav-sideButton:hover .TopNav-sideButtonLine {
  width: 100%;
}
.isDesktop .TopNav--sideTopNav .TopNav-sideButton:hover .TopNav-sideButtonLine--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
}
.isDesktop .TopNav--sideTopNav .TopNav-sideButton:hover .TopNav-sideButtonLine--3 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
}
.TopNav--sideTopNav .TopNav-sideButtonLine {
  -webkit-transition: 0.2s width ease-out, 0.2s top ease-out, 0.2s left ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s width ease-out, 0.2s top ease-out, 0.2s left ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s width ease-out, 0.2s top ease-out, 0.2s left ease-out, 0.2s transform 0.2s ease-out;
  transition: 0.2s width ease-out, 0.2s top ease-out, 0.2s left ease-out, 0.2s transform 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
}
.isMobile .TopNav--sideTopNav .TopNav-sideButtonLine {
  background: #ffffff;
}
.TopNav--sideTopNav .TopNav-sideButtonLine--1 {
  top: 50%;
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
}
.isMobile .TopNav--sideTopNav .TopNav-sideButtonLine--1 {
  top: 50%;
}
.TopNav--sideTopNav .TopNav-sideButtonLine--2 {
  opacity: 0;
}
.TopNav--sideTopNav .TopNav-sideButtonLine--3 {
  top: 50%;
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
}
.isMobile .TopNav--sideTopNav .TopNav-sideButtonLine--3 {
  top: 50%;
}
.isMobile .TopNav--sideTopNav .TopNav-sideButtonText {
  display: none;
}
.TopNav-content {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.isMobile .TopNav-content {
  width: 100%;
  padding: 0 20px;
}
.TopNav-sideButton {
  position: absolute;
  top: 40px;
  left: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  cursor: pointer;
  pointer-events: all;
}
.isMobile .TopNav-sideButton {
  top: 20px;
  left: 20px;
}
.isDesktop .TopNav-sideButton:hover .TopNav-sideButtonLine--1 {
  -webkit-transform: translate3d(-50%, -2px, 0);
          transform: translate3d(-50%, -2px, 0);
}
.isDesktop .TopNav-sideButton:hover .TopNav-sideButtonLine--3 {
  -webkit-transform: translate3d(-50%, 2px, 0);
          transform: translate3d(-50%, 2px, 0);
}
.TopNav-sideButtonShape {
  position: relative;
  width: 30px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isMobile .TopNav-sideButtonShape {
  width: 20px;
  height: 17px;
}
.TopNav-sideButtonLine {
  position: absolute;
  left: 50%;
  right: 0;
  width: 24px;
  height: 3px;
  background: #383633;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: 0.2s opacity ease-out, 0.2s top 0.2s ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s top 0.2s ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s top 0.2s ease-out, 0.2s transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s top 0.2s ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .TopNav-sideButtonLine {
  width: 20px;
}
.SearchPage .TopNav-sideButtonLine,
.TechPage .TopNav-sideButtonLine,
.DevPage .TopNav-sideButtonLine {
  background: #ffffff;
}
.TopNav-sideButtonLine--1 {
  top: 2px;
}
.isMobile .TopNav-sideButtonLine--1 {
  top: 0;
}
.TopNav-sideButtonLine--2 {
  top: 11px;
}
.isMobile .TopNav-sideButtonLine--2 {
  top: 7px;
}
.TopNav-sideButtonLine--3 {
  top: 20px;
}
.isMobile .TopNav-sideButtonLine--3 {
  top: 14px;
}
.TopNav-sideButtonText {
  font-size: 30px;
  line-height: 1;
  letter-spacing: -0.03em;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  text-transform: lowercase;
  margin-left: 17px;
  margin-top: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isMobile .TopNav-sideButtonText {
  font-size: 24px;
  margin-left: 10px;
  margin-top: -5px;
}
.SearchPage .TopNav-sideButtonText,
.TechPage .TopNav-sideButtonText,
.DevPage .TopNav-sideButtonText {
  color: #ffffff;
}
