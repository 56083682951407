.Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 6;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  min-width: 1120px;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .Footer {
  display: none;
}
.Footer.isOpenMenu {
  -webkit-transform: translateX(30%);
      -ms-transform: translateX(30%);
          transform: translateX(30%);
}
.SearchExtraPage .Footer {
  padding-left: 0;
  padding-right: 0;
}
.Page404 .Footer {
  display: none;
  opacity: 0 !important;
  visibility: hidden;
}
.NotFoundClass .Footer {
  display: none;
  opacity: 0 !important;
  visibility: hidden;
}
.Footer-outter {
  padding-bottom: 55px;
  -webkit-transition: none;
  transition: none;
  will-change: transform;
}
.Footer-overlay {
  position: absolute;
  top: -250%;
  bottom: 0;
  left: -50%;
  right: 0;
  will-change: opacity;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.TruePage .Footer-overlay,
.true .Footer-overlay {
  background: #d6d2c8;
}
.SearchPage .Footer-overlay,
.search .Footer-overlay {
  background: #b4463d;
}
.TechPage .Footer-overlay,
.tech .Footer-overlay {
  background: #51a29e;
}
.DevPage .Footer-overlay,
.dev .Footer-overlay {
  background: #142f81;
}
.true.isHover .Footer-overlay {
  background: #d6d2c8;
}
.search.isHover .Footer-overlay {
  background: #b4463d;
}
.tech.isHover .Footer-overlay {
  background: #51a29e;
}
.dev.isHover .Footer-overlay {
  background: #142f81;
}
.Footer-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: auto;
  width: 77.77777778%;
  padding-right: 100px;
  padding-left: 100px;
}
@media (min-width: 1920px) {
}
.SearchExtraPage .Footer-wrapper {
  width: 70.83333333%;
  margin: 0 auto;
  padding-right: 0;
}
.Footer-top {
  padding-top: 67px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.Footer-topTitle {
  font-size: 18px;
  line-height: 25px;
  font-family: "FFMarkWebPro";
  font-weight: 700;
  color: #383633;
  letter-spacing: 0em;
  line-height: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #31302b;
}
.DevPage .Footer-topTitle {
  color: #ffffff;
}
.Footer-topClocks {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.Footer-topClocks:after {
  content: '';
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
.Footer-topClocksItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 14.18181818%;
          flex: 0 0 14.18181818%;
  padding: 0 1.81818182%;
}
.Footer-topClocks--countItems-0 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 Infinity%;
          flex: 0 0 Infinity%;
  padding: 0;
}
.Footer-topClocks--countItems-0 .Footer-topClocksItem:nth-child(n + 1) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-1 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 0;
}
.Footer-topClocks--countItems-1 .Footer-topClocksItem:nth-child(n + 2) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-2 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  padding: 0;
}
.Footer-topClocks--countItems-2 .Footer-topClocksItem:nth-child(n + 3) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-3 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
          flex: 0 0 33.33333333%;
  padding: 0;
}
.Footer-topClocks--countItems-3 .Footer-topClocksItem:nth-child(n + 4) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-4 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  padding: 0;
}
.Footer-topClocks--countItems-4 .Footer-topClocksItem:nth-child(n + 5) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-5 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  padding: 0;
}
.Footer-topClocks--countItems-5 .Footer-topClocksItem:nth-child(n + 6) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-6 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
          flex: 0 0 16.66666667%;
  padding: 0;
}
.Footer-topClocks--countItems-6 .Footer-topClocksItem:nth-child(n + 7) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-7 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 14.28571429%;
          flex: 0 0 14.28571429%;
  padding: 0;
}
.Footer-topClocks--countItems-7 .Footer-topClocksItem:nth-child(n + 8) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-8 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
  padding: 0;
}
.Footer-topClocks--countItems-8 .Footer-topClocksItem:nth-child(n + 9) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-9 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 11.11111111%;
          flex: 0 0 11.11111111%;
  padding: 0;
}
.Footer-topClocks--countItems-9 .Footer-topClocksItem:nth-child(n + 10) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-10 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
          flex: 0 0 10%;
  padding: 0;
}
.Footer-topClocks--countItems-10 .Footer-topClocksItem:nth-child(n + 11) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-11 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 9.09090909%;
          flex: 0 0 9.09090909%;
  padding: 0;
}
.Footer-topClocks--countItems-11 .Footer-topClocksItem:nth-child(n + 12) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-12 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333333%;
          flex: 0 0 8.33333333%;
  padding: 0;
}
.Footer-topClocks--countItems-12 .Footer-topClocksItem:nth-child(n + 13) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-13 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 7.69230769%;
          flex: 0 0 7.69230769%;
  padding: 0;
}
.Footer-topClocks--countItems-13 .Footer-topClocksItem:nth-child(n + 14) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-14 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 7.14285714%;
          flex: 0 0 7.14285714%;
  padding: 0;
}
.Footer-topClocks--countItems-14 .Footer-topClocksItem:nth-child(n + 15) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-15 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 6.66666667%;
          flex: 0 0 6.66666667%;
  padding: 0;
}
.Footer-topClocks--countItems-15 .Footer-topClocksItem:nth-child(n + 16) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-16 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 6.25%;
          flex: 0 0 6.25%;
  padding: 0;
}
.Footer-topClocks--countItems-16 .Footer-topClocksItem:nth-child(n + 17) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-17 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 5.88235294%;
          flex: 0 0 5.88235294%;
  padding: 0;
}
.Footer-topClocks--countItems-17 .Footer-topClocksItem:nth-child(n + 18) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-18 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 5.55555556%;
          flex: 0 0 5.55555556%;
  padding: 0;
}
.Footer-topClocks--countItems-18 .Footer-topClocksItem:nth-child(n + 19) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-19 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 5.26315789%;
          flex: 0 0 5.26315789%;
  padding: 0;
}
.Footer-topClocks--countItems-19 .Footer-topClocksItem:nth-child(n + 20) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-20 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 5%;
          flex: 0 0 5%;
  padding: 0;
}
.Footer-topClocks--countItems-20 .Footer-topClocksItem:nth-child(n + 21) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-21 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 4.76190476%;
          flex: 0 0 4.76190476%;
  padding: 0;
}
.Footer-topClocks--countItems-21 .Footer-topClocksItem:nth-child(n + 22) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-22 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 4.54545455%;
          flex: 0 0 4.54545455%;
  padding: 0;
}
.Footer-topClocks--countItems-22 .Footer-topClocksItem:nth-child(n + 23) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-23 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 4.34782609%;
          flex: 0 0 4.34782609%;
  padding: 0;
}
.Footer-topClocks--countItems-23 .Footer-topClocksItem:nth-child(n + 24) {
  margin-top: 40px;
}
.Footer-topClocks--countItems-24 .Footer-topClocksItem {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 4.16666667%;
          flex: 0 0 4.16666667%;
  padding: 0;
}
.Footer-topClocks--countItems-24 .Footer-topClocksItem:nth-child(n + 25) {
  margin-top: 40px;
}
.Footer-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding-top: 50px;
  border-top: 1px solid #b3b3b3;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SearchPage .Footer-bottom {
  border-top: 1px solid #c9918c;
}
.Footer-bottomNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.Footer-bottomNav span,
.Footer-bottomNav p {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  display: block;
  margin-top: 1.5em;
}
.SearchPage .Footer-bottomNav span,
.SearchPage .Footer-bottomNav p,
.TechPage .Footer-bottomNav span,
.TechPage .Footer-bottomNav p,
.DevPage .Footer-bottomNav span,
.DevPage .Footer-bottomNav p {
  color: #ffffff;
}
.Footer-bottomNav span:first-child,
.Footer-bottomNav p:first-child {
  margin-top: 0;
}
.Footer-bottomNav span.address,
.Footer-bottomNav p.address {
  margin-top: 0;
  white-space: pre-line;
}
.Footer-bottomNav span.Footer-bottomNavTitle,
.Footer-bottomNav p.Footer-bottomNavTitle {
  font-weight: 700;
}
.Footer-bottomNav span a,
.Footer-bottomNav p a {
  color: #383633;
  text-decoration: none;
}
.SearchPage .Footer-bottomNav span a,
.SearchPage .Footer-bottomNav p a,
.TechPage .Footer-bottomNav span a,
.TechPage .Footer-bottomNav p a,
.DevPage .Footer-bottomNav span a,
.DevPage .Footer-bottomNav p a {
  color: #ffffff;
}
.Footer-bottomNav span a:hover,
.Footer-bottomNav p a:hover {
  text-decoration: underline;
}
.Footer-bottomNav p {
  margin-top: 0;
}
.Footer-bottomNav p span {
  margin-top: 0;
}
.Footer-bottomCopyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.Footer-bottomCopyright span {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  display: block;
  color: #808080;
}
.SearchPage .Footer-bottomCopyright span {
  color: #c9918c;
}
.TechPage .Footer-bottomCopyright span {
  color: #8dc4c1;
}
.DevPage .Footer-bottomCopyright span {
  color: #5c83d1;
}
