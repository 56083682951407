.PageSlackSelect {
  min-width: 500px;
  background: #f4f5f6;
}
.SlackSection {
  background: #ffffff;
}
.SlackSelect {
  background-color: #ffffff;
  padding-bottom: 10px;
}
.SlackSelect-center {
  text-align: center;
  width: 425px;
  margin: 100px auto 50px;
  position: relative;
}
.SlackSelect-list {
  background: white;
  border: 1px solid #ccc;
  text-align: left;
  padding: 5px 0;
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
}
.SlackSelect-list li {
  padding: 3px 10px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.05em;
  color: #383633;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SlackSelect-title {
  padding-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SlackSelect-title span {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  padding-bottom: 25px;
}
.isMobile .SlackSelect-title span {
  font-size: 36px;
  line-height: 40px;
}
.SlackSelect-img {
  width: 100px;
  display: inline;
}
.SlackSelect-submit {
  margin-top: 50px;
  padding: 7px 40px 10px;
  background-color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 24px;
  color: #ffffff;
  outline: none;
  border: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-weight: 500;
  letter-spacing: -0.05em;
  cursor: pointer;
}
.SlackSelect-submit:hover {
  opacity: 0.7;
}
.SlackSelect-titleChannel {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
  position: relative;
  font-family: "FFMarkWebPro";
  font-size: 16px;
  color: #383633;
  font-weight: 500;
  letter-spacing: -0.05em;
}
.SlackSelect .SelectOtherTeam {
  margin-left: 72px;
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
  letter-spacing: -0.05em;
}
.SlackSelect .SelectOtherTeam:hover {
  text-decoration: underline;
}
