.FooterClockItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 120px;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isSideNav .FooterClockItem {
  max-width: 150px;
}
.FooterClockItem-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  width: 100%;
  position: relative;
  margin: auto;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.FooterClockItem-clock:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.TruePage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-clock,
.TruePage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-clock,
.TruePage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-clock,
.SearchPage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-clock,
.SearchPage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-clock,
.SearchPage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-clock,
.isSideNav:nth-child(7n + 1) .FooterClockItem-clock,
.isSideNav:nth-child(7n + 4) .FooterClockItem-clock,
.isSideNav:nth-child(7n + 6) .FooterClockItem-clock {
  background: #f5371f;
}
.TruePage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-clock,
.TruePage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-clock,
.SearchPage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-clock,
.SearchPage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-clock,
.isSideNav:nth-child(7n + 2) .FooterClockItem-clock,
.isSideNav:nth-child(7n + 5) .FooterClockItem-clock {
  background: #8f897a;
}
.TruePage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-clock,
.TruePage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-clock,
.SearchPage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-clock,
.SearchPage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-clock {
  background: #31302b;
}
.isSideNav:nth-child(7n + 3) .FooterClockItem-clock,
.isSideNav:nth-child(7n + 7) .FooterClockItem-clock {
  background: #212019;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-clock,
.TechPage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-clock,
.TechPage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-clock {
  background: #027973;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-clock,
.TechPage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-clock {
  background: #e4ce97;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-clock,
.TechPage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-clock {
  background: #071f1a;
}
.DevPage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-clock,
.DevPage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-clock,
.DevPage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-clock {
  background: #89e5e1;
}
.DevPage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-clock,
.DevPage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-clock {
  background: #e1b182;
}
.DevPage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-clock,
.DevPage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-clock {
  background: #0b1e43;
}
.FooterClockItem-clockName {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  text-align: center;
  color: #808080;
  margin-top: 30px;
}
.TechPage .FooterClockItem-clockName {
  color: #8dc4c1;
}
.SearchPage .FooterClockItem-clockName {
  color: #c9918c;
}
.FooterClockItem-clockOffice {
  margin-top: 30px;
  width: 110%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.FooterClockItem-clockOfficeItem {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  line-height: 2.14285714;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #808080;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  cursor: default;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
}
.TeamMemberPopup .FooterClockItem-clockOfficeItem {
  line-height: 1.42857143;
}
.FooterClockItem-clockOfficeItemName {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName {
  text-decoration: underline;
}
.FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:hover,
.FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:active {
  color: #f5371f;
}
.isMobile .FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:hover,
.isMobile .FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:active {
  text-decoration: none;
}
.FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:hover:after,
.FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:active:after {
  opacity: 0;
}
.FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:hover + .FooterClockItem-clockOfficeItem-tooltip,
.FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:active + .FooterClockItem-clockOfficeItem-tooltip {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  border-bottom: 1px dashed;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.isMobile .FooterClockItem-clockOfficeItem--withTooltip .FooterClockItem-clockOfficeItemName:after {
  display: none;
}
.TechPage .FooterClockItem-clockOfficeItem {
  color: #8dc4c1;
}
.DevPage .FooterClockItem-clockOfficeItem {
  color: #5c83d1;
}
.SearchPage .FooterClockItem-clockOfficeItem {
  color: #c9918c;
}
.FooterClockItem-clockOfficeItem-tooltip {
  position: absolute;
  bottom: 100%;
  background: #ffffff;
  width: 220px;
  padding: 20px 20px 15px;
  left: 0;
  font-size: 14px;
  line-height: 1.42857143;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #31302b;
  font-family: "CharterITCStd";
  opacity: 0;
  z-index: 200;
  visibility: hidden;
  -webkit-box-shadow: 20px 0 20px rgba(0, 0, 0, 0.2);
          box-shadow: 20px 0 20px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s opacity ease-out, 0s visibility 0.2s ease-out;
  transition: 0.2s opacity ease-out, 0s visibility 0.2s ease-out;
}
.isMobile .FooterClockItem-clockOfficeItem-tooltip {
  width: auto;
  left: -25px;
  right: -25px;
  padding: 25px 20px 15px;
}
.FooterClockItem-clockOfficeItem-tooltip:hover,
.FooterClockItem-clockOfficeItem-tooltip:active {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.FooterClockItem-clockOfficeItem-tooltipText {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: left;
}
.isMobile .FooterClockItem-clockOfficeItem-tooltipText {
  width: 100%;
}
.FooterClockItem-clockOfficeItem-tooltipText a {
  color: #f5371f;
  text-decoration: none;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
}
.isDesktop .FooterClockItem-clockOfficeItem-tooltipText a:hover {
  color: #31302b;
}
.FooterClockItem-clockOfficeItem-tooltipLink {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  color: #f5371f;
  margin-top: 20px;
  -webkit-transition: 0.2s color ease-out;
  transition: 0.2s color ease-out;
}
.isDesktop .FooterClockItem-clockOfficeItem-tooltipLink:hover {
  color: #31302b;
}
.FooterClockItem-spire {
  position: absolute;
}
.FooterClockItem-spire--hour {
  top: calc(50% - 10px);
  left: calc(50% - 3px);
  width: 6%;
  height: 35%;
  z-index: 4;
  -webkit-transform-origin: 3px 10px;
      -ms-transform-origin: 3px 10px;
          transform-origin: 3px 10px;
  -webkit-transition: -webkit-transform 2s ease;
  transition: -webkit-transform 2s ease;
  transition: transform 2s ease;
  transition: transform 2s ease, -webkit-transform 2s ease;
}
.TruePage .FooterClockItem-spire--hour,
.SearchPage .FooterClockItem-spire--hour {
  background: #d6d2c8;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-spire--hour,
.TechPage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-spire--hour,
.TechPage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-spire--hour {
  background: #ffffff;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-spire--hour,
.TechPage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-spire--hour {
  background: #31302b;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-spire--hour,
.TechPage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-spire--hour {
  background: #d6d2c8;
}
.DevPage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-spire--hour,
.DevPage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-spire--hour,
.DevPage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-spire--hour,
.DevPage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-spire--hour,
.DevPage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-spire--hour {
  background: #31302b;
}
.DevPage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-spire--hour,
.DevPage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-spire--hour {
  background: #d6d2c8;
}
.FooterClockItem-spire--min {
  top: calc(50% - 10px);
  left: calc(50% - 1px);
  width: 2%;
  height: 50%;
  z-index: 3;
  -webkit-transform-origin: 1px 10px;
      -ms-transform-origin: 1px 10px;
          transform-origin: 1px 10px;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
}
.TruePage .FooterClockItem-spire--min,
.SearchPage .FooterClockItem-spire--min {
  background: #d6d2c8;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-spire--min,
.TechPage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-spire--min,
.TechPage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-spire--min {
  background: #ffffff;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-spire--min,
.TechPage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-spire--min {
  background: #31302b;
}
.TechPage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-spire--min,
.TechPage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-spire--min {
  background: #d6d2c8;
}
.DevPage .Footer-topClocksItem:nth-child(7n + 1) .FooterClockItem-spire--min,
.DevPage .Footer-topClocksItem:nth-child(7n + 2) .FooterClockItem-spire--min,
.DevPage .Footer-topClocksItem:nth-child(7n + 4) .FooterClockItem-spire--min,
.DevPage .Footer-topClocksItem:nth-child(7n + 5) .FooterClockItem-spire--min,
.DevPage .Footer-topClocksItem:nth-child(7n + 7) .FooterClockItem-spire--min {
  background: #31302b;
}
.DevPage .Footer-topClocksItem:nth-child(7n + 3) .FooterClockItem-spire--min,
.DevPage .Footer-topClocksItem:nth-child(7n + 6) .FooterClockItem-spire--min {
  background: #d6d2c8;
}
