.PrivacyPolicyBlock {
  background-color: #ffffff;
  padding: 86px 100px 117px 100px;
}
.isMobile .PrivacyPolicyBlock {
  padding: 38px 0 80px;
}
.PrivacyPolicyBlock-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
}
.isMobile .PrivacyPolicyBlock-title {
  font-size: 36px;
  line-height: 40px;
}
.PrivacyPolicyBlock-content {
  color: #383633;
  margin-top: 91px;
  font-family: "CharterITCStd";
  font-size: 18px;
  line-height: 1.667;
}
.isMobile .PrivacyPolicyBlock-content {
  margin-top: 40px;
  font-size: 16px;
}
.PrivacyPolicyBlock-content h2 {
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
}
.isMobile .PrivacyPolicyBlock-content h2 {
  font-size: 24px;
  line-height: 1.2;
}
.PrivacyPolicyBlock-content ul {
  margin-left: 40px;
}
.PrivacyPolicyBlock-content ul li {
  position: relative;
  display: block;
}
.PrivacyPolicyBlock-content ul li:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #383633;
  left: -22px;
  top: 10px;
  font-size: 3em;
}
.PrivacyPolicyBlock-content ol {
  list-style: decimal;
  margin-top: 1.7em;
  margin-left: 40px;
}
.isMobile .PrivacyPolicyBlock-content ol {
  margin-left: 22px;
}
.PrivacyPolicyBlock-content a {
  text-decoration: none;
  color: #f5371f;
}
.isDesktop .PrivacyPolicyBlock-content a:hover {
  text-decoration: underline;
}
