.SearchOurExpertise {
  background-color: #e5e4df;
  padding: 86px 100px 112px;
}
.isMobile .SearchOurExpertise {
  padding: 37px 0 80px;
}
.SearchOurExpertise-wrapper {
  width: 100%;
}
.SearchOurExpertise-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  margin-left: -4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .SearchOurExpertise-title {
  font-size: 36px;
  line-height: 40px;
}
.SearchOurExpertise-titleReach {
  margin-top: 100px;
}
.isMobile .SearchOurExpertise-titleReach {
  margin-top: 50px;
}
.SearchOurExpertise-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 65px;
  font-family: "CharterITCStd";
}
.isMobile .SearchOurExpertise-content {
  margin-top: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.SearchOurExpertise-description {
  -ms-flex-preferred-size: 26%;
      flex-basis: 26%;
  font-size: 18px;
  color: #808080;
  line-height: 1.667;
  margin-top: 30px;
}
.isMobile .SearchOurExpertise-description {
  margin-top: 0;
  font-size: 16px;
  line-height: 25px;
}
.SearchOurExpertise-linkList {
  width: 100%;
  -ms-flex-preferred-size: 66%;
      flex-basis: 66%;
  margin-left: 80px;
}
.isMobile .SearchOurExpertise-linkList {
  margin-left: 0;
  margin-top: 50px;
}
.SearchOurExpertise-linkListSection {
  margin-top: 105px;
}
.SearchOurExpertise-linkListSection:first-child {
  margin-top: 0;
}
.SearchOurExpertise-link {
  border-bottom: 1px #d8d8d8 solid;
  font-size: 36px;
  color: #333333;
  line-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.isMobile .SearchOurExpertise-link {
  font-size: 24px;
  line-height: 1;
}
.dark .SearchOurExpertise-link {
  color: #ffffff;
  border-color: #4c4a48;
}
.SearchOurExpertise-link:hover {
  color: #f5371f;
}
.SearchOurExpertise-link:hover .SearchOurExpertise-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.SearchOurExpertise-link:last-child {
  border-bottom: none;
}
.SearchOurExpertise-link:last-of-type {
  border-bottom: none;
}
.SearchOurExpertise-linkTitle {
  max-width: calc(100% - 80px);
}
.SearchOurExpertise-linkArrow {
  width: 69px;
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  width: 60.5px;
  height: 34px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -17px;
}
.isDesktop .SearchOurExpertise-linkArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .SearchOurExpertise-linkArrow {
  width: 48px;
  height: 26px;
}
.SearchOurExpertise-linkArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .SearchOurExpertise-linkArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.SearchOurExpertise-more {
  color: #f5371f;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  margin-top: 30px;
}
.isMobile .SearchOurExpertise-more {
  font-size: 16px;
  line-height: 20px;
}
.SearchOurExpertise-more a {
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -0.9px;
  color: #f5371f;
}
.SearchOurExpertise-more a:visited {
  color: #f5371f;
}
.SearchOurExpertise-more a:hover {
  text-decoration: underline;
}
.SearchOurExpertise-reachText {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66%;
          flex: 0 0 66%;
  line-height: 30px;
  font-size: 18px;
  color: #383633;
  font-family: "CharterITCStd";
}
.isMobile .SearchOurExpertise-reachText {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  font-size: 16px;
}
.SearchOurExpertise-reachContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 50px;
}
.isMobile .SearchOurExpertise-reachContent {
  margin-top: 25px;
}
