.OurServices {
  background-color: #e5e4df;
  padding: 86px 100px 90px;
}
.isMobile .OurServices {
  padding: 39px 0 30px;
}
.OurServices-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
}
.isMobile .OurServices-title {
  font-size: 36px;
  line-height: 40px;
}
.OurServices-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 92px;
}
.isMobile .OurServices-content {
  display: block;
  margin-top: 35px;
}
.OurServices-leftSide {
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
}
.OurServices-rightSide {
  -ms-flex-preferred-size: 60%;
      flex-basis: 60%;
}
.isMobile .OurServices-rightSide {
  margin-top: 40px;
}
.OurServices-description {
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #808080;
  line-height: 1.667;
  margin-top: 7px;
}
.isMobile .OurServices-description {
  margin-top: 0;
  font-size: 16px;
}
.OurServices-link {
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  display: block;
}
.OurServices-link:last-of-type .OurServices-service {
  border-bottom: none;
}
.OurServices-link:hover .OurServices-serviceTitle {
  color: #3b6dca;
}
.OurServices-link:hover .OurServices-serviceDescription {
  color: #383633;
}
.OurServices-link:hover .OurServices-serviceArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.OurServices-service {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #cecdc8;
  position: relative;
}
.isMobile .OurServices-service {
  padding-bottom: 25px;
  margin-bottom: 15px;
}
.OurServices-serviceTitle {
  font-family: "CharterITCStd";
  font-size: 36px;
  color: #383633;
  max-width: 80%;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.isMobile .OurServices-serviceTitle {
  font-size: 24px;
}
.OurServices-serviceDescription {
  font-family: "CharterITCStd";
  font-size: 18px;
  color: #999999;
  width: 80%;
  margin-top: 25px;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.isMobile .OurServices-serviceDescription {
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
  width: 100%;
}
.OurServices-serviceArrow {
  width: 69px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 59px;
  height: 32px;
}
.isDesktop .OurServices-serviceArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .OurServices-serviceArrow {
  width: 48px;
  height: 26px;
}
.OurServices-serviceArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .OurServices-serviceArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .OurServices-serviceArrow {
  width: 35px;
  height: 25px;
  cursor: pointer;
  overflow: hidden;
}
.isDesktop .isMobile .OurServices-serviceArrow:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .isMobile .OurServices-serviceArrow {
  width: 48px;
  height: 26px;
}
.isMobile .OurServices-serviceArrow svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-5px, 0, 0);
          transform: translate3d(-5px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .isMobile .OurServices-serviceArrow svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .OurServices-serviceArrow svg {
  fill: #3b6dca;
}
.OurServices-serviceArrow svg {
  fill: #3b6dca;
}
