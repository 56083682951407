.SideNavMobile {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 20;
  pointer-events: none;
  display: none;
  opacity: 0;
  visibility: hidden;
  background: #383633;
}
.SideNavMobile--visible {
  visibility: visible;
  opacity: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
}
.SideNavMobile-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 80px 40px 75px;
  overflow: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}
.SideNavMobile-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.SideNavMobile-linksItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px 0 20px 0;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.SideNavMobile-linksItem:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.SideNavMobile-linksItemLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.SideNavMobile-linksItemLinkCross {
  width: 20px;
  height: 20px;
  position: relative;
}
.SideNavMobile-linksItemLinkCrossItem {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background: #f5371f;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
.SideNavMobile-linksItemLinkCrossItem:last-child {
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(90deg);
          transform: translate3d(-50%, -50%, 0) rotate(90deg);
}
.isActive .SideNavMobile-linksItemLinkCrossItem:last-child {
  opacity: 0;
}
.SideNavMobile-linksItemMenu {
  display: none;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.isActive .SideNavMobile-linksItemMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SideNavMobile-linksItemMenuLink {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  color: #ffffff;
  line-height: 25px;
  text-decoration: none;
}
.isMobile .SideNavMobile-linksItemMenuLink {
  font-size: 16px;
  line-height: 20px;
}
.SideNavMobile-linksItemMenuLink.isActive {
  color: #f5371f;
}
.isMobile .SideNavMobile-linksItemMenuLink {
  font-size: 18px;
  line-height: 30px;
}
.SideNavMobile-link {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 65px;
  text-decoration: none;
  color: #ffffff;
}
.isMobile .SideNavMobile-link {
  font-size: 36px;
  line-height: 40px;
}
.SideNavMobile-link.isActive,
.TruePage .SideNavMobile-link[data-id="true"],
.SearchPage .SideNavMobile-link[data-id="search"],
.TechPage .SideNavMobile-link[data-id="tech"],
.DevPage .SideNavMobile-link[data-id="dev"] {
  color: transparent;
  -webkit-text-stroke: 1px #ffffff;
  -moz-text-stroke: 1px #ffffff;
  text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  cursor: default;
}
.SideNavMobile-footer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.SideNavMobile-footerLinks {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 64px;
}
.SideNavMobile-footerLink {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 35px;
}
.SideNavMobile-footerLink:first-child {
  margin-top: 0;
}
.SideNavMobile-footerLink a {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  text-decoration: none;
  color: #ffffff;
  font-weight: normal;
}
.isMobile .SideNavMobile-footerLink a {
  font-size: 16px;
  line-height: 25px;
}
.SideNavMobile-footerLink span {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #ffffff;
  width: 100%;
  font-weight: 700;
}
.isMobile .SideNavMobile-footerLink span {
  font-size: 16px;
  line-height: 25px;
}
.SideNavMobile-footerBottom {
  padding-top: 27px;
  width: 100%;
  margin-top: 77px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.SideNavMobile-footerBottomTitle {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  line-height: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #808080;
}
.isMobile .SideNavMobile-footerBottomTitle {
  font-size: 16px;
  line-height: 20px;
}
.SideNavMobile-footerBottomClocks {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.SideNavMobile-footerBottomClocksItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 39.28571429%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-bottom: 40px;
}
.SideNavMobile-footerCopyright {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  margin-top: 35px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #808080;
}
