.Nutshell {
  background-color: #383633;
  padding: 0 0 100px 0;
}
.isMobile .Nutshell {
  padding: 0 0 20px 0;
}
.Nutshell-topWrapper {
  padding: 86px 100px 117px;
  width: 100%;
}
.isMobile .Nutshell-topWrapper {
  padding: 38px 20px 0;
}
.Nutshell-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #ffffff;
}
.isMobile .Nutshell-title {
  font-size: 36px;
  line-height: 40px;
}
.Nutshell-facts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 74px;
  width: 100%;
}
.isMobile .Nutshell-facts {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 40px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Nutshell-fact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
}
.Nutshell-fact:first-child {
  margin-left: 0;
}
.Nutshell-fact:last-child {
  margin-right: 0;
}
.isMobile .Nutshell-fact {
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
  margin: 0 0 60px 0;
}
.Nutshell-factLine {
  width: 100%;
  height: 2px;
  background: #ffffff;
  opacity: 0.1;
}
.Nutshell-factTitle {
  margin-top: 15px;
  margin-left: -5px;
  color: #999999;
  font-family: Charter, sans-serif;
  font-size: 64px;
  letter-spacing: -3px;
}
.isMobile .Nutshell-factTitle {
  font-size: 48px;
}
.Nutshell-factDescription {
  color: #ffffff;
  font-family: Charter, sans-serif;
  font-size: 14px;
  line-height: 1.389;
  margin-top: 10px;
  max-width: 80%;
}
.isMobile .Nutshell-factDescription {
  font-size: 14px;
}
.Nutshell-logosWrapper {
  width: 100%;
  overflow: hidden;
}
