.CommonBlock {
  position: relative;
  width: 100%;
  margin: 45px auto 0;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .CommonBlock {
  width: 100%;
}
.CommonBlock--text {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #383633;
  margin-top: 52px;
}
.isMobile .CommonBlock--text {
  font-size: 16px;
  line-height: 25px;
}
@media print {
  .CommonBlock--text {
    color: #000000;
  }
}
.CommonBlock--text ul {
  margin-top: 1.7em;
  margin-left: 40px;
}
.isMobile .CommonBlock--text ul {
  margin-left: 20px;
}
.CommonBlock--text ul li {
  position: relative;
}
.CommonBlock--text ul li:before {
  content: "·";
  position: absolute;
  left: -22px;
  top: 3px;
  font-size: 3em;
}
.CommonBlock--text ol {
  list-style: decimal;
  margin-top: 1.7em;
  margin-left: 40px;
}
.isMobile .CommonBlock--text ol {
  margin-left: 20px;
}
.CommonBlock--text a {
  text-decoration: none;
  color: #f5371f;
}
@media print {
  .CommonBlock--text a {
    color: #000000;
  }
}
.isDesktop .CommonBlock--text a:hover {
  text-decoration: underline;
}
.CommonBlock--text h2 {
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
}
.isMobile .CommonBlock--text h2 {
  font-size: 24px;
}
.isMobile .CommonBlock--text {
  line-height: 30px;
  margin-top: 39px;
}
.CommonBlock--quote {
  margin-top: 58px;
}
.isMobile .CommonBlock--quote {
  margin-top: 60px;
}
.CommonBlock--image {
  margin-top: 62px;
}
.isMobile .CommonBlock--image {
  margin-top: 45px;
}
.CommonBlock--image .CommonBlock-img {
  position: relative;
}
.CommonBlock--image .CommonBlock-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  position: relative;
}
.CommonBlock-caption {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  color: #808080;
  margin-top: 25px;
}
.CommonBlock-caption ul {
  margin-top: 1.7em;
  margin-left: 40px;
}
.isMobile .CommonBlock-caption ul {
  margin-left: 20px;
}
.CommonBlock-caption ul li {
  position: relative;
}
.CommonBlock-caption ul li:before {
  content: "·";
  position: absolute;
  left: -22px;
  top: 3px;
  font-size: 3em;
}
.CommonBlock-caption ol {
  list-style: decimal;
  margin-top: 1.7em;
  margin-left: 40px;
}
.isMobile .CommonBlock-caption ol {
  margin-left: 20px;
}
.CommonBlock-caption a {
  text-decoration: none;
  color: #f5371f;
}
.isDesktop .CommonBlock-caption a:hover {
  text-decoration: underline;
}
.isMobile .CommonBlock-caption {
  margin-top: 30px;
}
.CommonBlock--embed {
  margin-top: 62px;
}
.isMobile .CommonBlock--embed {
  margin-top: 45px;
}
.CommonBlock--embed * {
  width: 100% !important;
}
.CommonBlock--embed a {
  font-family: "CharterITCStd";
  text-decoration: none;
  color: #f5371f;
}
.isDesktop .CommonBlock--embed a:hover {
  text-decoration: underline;
}
.CommonBlock--gallery {
  margin-top: 62px;
}
.isMobile .CommonBlock--gallery {
  margin-top: 45px;
  margin-bottom: 46px;
}
.CommonBlock--video {
  margin-top: 62px;
}
.isMobile .CommonBlock--video {
  margin-top: 45px;
}
