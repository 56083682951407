.CommonSlider {
  position: relative;
  width: 100%;
}
.CommonSlider.isInitialState .CommonSlider-slider {
  opacity: 0;
}
.CommonSlider-slider {
  width: 100%;
  z-index: 1;
  position: relative;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.isMobile .CommonSlider-slider {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.CommonSlider-sliderList {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.CommonSlider-sliderList:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 57.89473684%;
}
.isMobile .CommonSlider-sliderList {
  position: relative;
}
.isMobile .CommonSlider-sliderList:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 62.5%;
}
.CommonSlider-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.CommonSlider-slide.isActive {
  opacity: 1;
  z-index: 2;
}
.CommonSlider-slideImg {
  position: relative;
  width: 100%;
  height: 100%;
}
.CommonSlider-slideImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.CommonSlider-panel {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.CommonSlider-panelList {
  position: relative;
  width: 100%;
  height: 100%;
}
.CommonSlider-panelSlide {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.isMobile .CommonSlider-panelSlide {
  position: relative;
  display: none;
}
.CommonSlider-panelSlide.isActive {
  opacity: 1;
  z-index: 2;
  display: block;
  position: relative;
}
.CommonSlider-panelSlideText {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  color: #808080;
  margin-top: 25px;
}
.isMobile .CommonSlider-panelSlideText {
  margin-top: 30px;
}
.CommonSlider-panelSlideText b {
  display: block;
  width: 100%;
}
.CommonSlider-panelSlideText a {
  color: #f5371f;
  text-decoration: underline;
}
.isDesktop .CommonSlider-panelSlideText a:hover {
  text-decoration: none;
}
.CommonSlider-navItem {
  width: 69px;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 50%;
  z-index: 2;
  right: -45px;
  width: 60px;
  height: 32px;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.isDesktop .CommonSlider-navItem:hover svg {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.isMobile .CommonSlider-navItem {
  width: 48px;
  height: 26px;
}
.CommonSlider-navItem svg {
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
  fill: #f5371f;
  -webkit-transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out;
  transition: 0.2s transform ease-out, 0.2s fill ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .CommonSlider-navItem svg {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.isMobile .CommonSlider-navItem {
  width: 30px;
  height: 20px;
  right: -20px;
}
.CommonSlider-navItem.isDisable {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}
.CommonSlider-navItem svg {
  width: 100%;
  height: 100%;
  fill: #ff3333;
}
.CommonSlider-navPrev {
  -webkit-transform: translate(0, -50%) rotate(180deg);
      -ms-transform: translate(0, -50%) rotate(180deg);
          transform: translate(0, -50%) rotate(180deg);
  left: -45px;
  right: auto;
}
.isMobile .CommonSlider-navPrev {
  left: -20px;
}
