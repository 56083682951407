.SearchClientsPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 26;
  -webkit-transition: 0s visibility 0.2s ease-out;
  transition: 0s visibility 0.2s ease-out;
  overflow-scrolling: touch;
  visibility: hidden;
}
.SearchClientsPopup--visible {
  visibility: visible;
  -webkit-transition: none;
  transition: none;
}
.SearchClientsPopup--visible .SearchClientsPopup-content {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
}
.SearchClientsPopup--visible .SearchClientsPopup-contentInner {
  opacity: 1;
  -webkit-transition: 0.2s opacity 0.4s ease-out;
  transition: 0.2s opacity 0.4s ease-out;
}
.SearchClientsPopup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.SearchClientsPopup-wrapper {
  width: 75%;
  min-width: 500px;
  margin-left: auto;
  position: relative;
  overflow-scrolling: touch;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 11;
}
.isMobile .SearchClientsPopup-wrapper {
  min-width: 100%;
  width: 100%;
}
.SearchClientsPopup-wrapper.overflowLock {
  overflow: hidden;
}
.SearchClientsPopup-content {
  background: #383633;
  padding: 86px 100px 86px 60px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  outline: none;
  overflow-scrolling: touch;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
.isMobile .SearchClientsPopup-content {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  padding: 60px 20px;
}
.SearchClientsPopup-contentInner {
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.SearchClientsPopup-close {
  position: fixed;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  z-index: 26;
}
.isMobile .SearchClientsPopup-close {
  top: 20px;
  right: 20px;
}
.SearchClientsPopup-close:hover .SearchClientsPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  width: 34px;
}
.SearchClientsPopup-close:hover .SearchClientsPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  width: 34px;
}
.SearchClientsPopup-closeItem {
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  background: #ffffff;
}
.SearchClientsPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.SearchClientsPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.isMobile .SearchClientsPopup-close {
  display: block;
}
.SearchClientsPopup-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  color: #ffffff;
  text-transform: none;
}
.isMobile .SearchClientsPopup-title {
  font-size: 36px;
  line-height: 40px;
}
.SearchClientsPopup-description {
  font-size: 18px;
  line-height: 30px;
  font-family: "CharterITCStd";
  font-weight: 500;
  color: #808080;
  letter-spacing: 0em;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: -40px;
}
.isMobile .SearchClientsPopup-description {
  font-size: 16px;
  line-height: 25px;
}
.SearchClientsPopup-clients {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 80px;
  margin-right: -1%;
  margin-left: -1%;
}
.SearchClientsPopup-client {
  -ms-flex-preferred-size: 23%;
      flex-basis: 23%;
  height: 190px;
  border-top: 1px solid #4c4a48;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin-right: 1%;
  margin-left: 1%;
}
.isMobile .SearchClientsPopup-client {
  -ms-flex-preferred-size: 46%;
      flex-basis: 46%;
}
.SearchClientsPopup-client.isCaseStudy {
  cursor: pointer;
}
.SearchClientsPopup-client.isCaseStudy:hover .SearchClientsPopup-clientLogo {
  opacity: 0.2;
}
.SearchClientsPopup-client.isCaseStudy:hover .SearchClientsPopup-clientCaseStudy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.SearchClientsPopup-client.isCaseStudy:hover .SearchClientsPopup-clientCaseStudy svg {
  width: 15px;
  height: 40px;
  fill: #f5371f;
}
.SearchClientsPopup-client.isCaseStudy:hover .SearchClientsPopup-clientCaseStudy span {
  display: block;
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  text-decoration: none;
  margin-left: 10px;
}
.isMobile .SearchClientsPopup-client.isCaseStudy .SearchClientsPopup-clientCaseStudy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .SearchClientsPopup-client.isCaseStudy .SearchClientsPopup-clientCaseStudy svg {
  width: 15px;
  height: 40px;
  fill: #f5371f;
}
.isMobile .SearchClientsPopup-client.isCaseStudy .SearchClientsPopup-clientCaseStudy span {
  display: block;
  color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
  text-decoration: none;
  margin-left: 10px;
}
.SearchClientsPopup-clientLogo {
  width: 70%;
  padding-bottom: 31.1%;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  position: relative;
}
.SearchClientsPopup-clientLogo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.SearchClientsPopup-clientCaseStudy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
.SearchClientsPopup-clientCaseStudy svg {
  width: 15px;
  height: 40px;
  fill: #807c7c;
}
.SearchClientsPopup-clientCaseStudy span {
  display: none;
}
.SearchClientsPopup-link {
  text-decoration: none;
}
