.RecentPlacements {
  padding-bottom: 54px;
}
.RecentPlacements-head {
  width: 100%;
  background-color: #f4f3f1;
  padding: 45px 60px 54px 60px;
}
.isMobile .RecentPlacements-head {
  padding: 34px 20px 34px 20px;
}
.RecentPlacements-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
}
.isMobile .RecentPlacements-title {
  font-size: 36px;
  line-height: 40px;
}
.RecentPlacements-filterRow {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.isMobile .RecentPlacements-filterRow {
  display: block;
  margin-top: 22px;
}
.RecentPlacements-filterList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 83%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.isMobile .RecentPlacements-filterList {
  display: block;
  width: 100%;
}
.RecentPlacements-filterItem {
  position: relative;
}
.RecentPlacements-filterItem:first-child {
  margin-left: 0;
}
.RecentPlacements-filterItem.active .RecentPlacements-filterOptions {
  display: block;
}
.isMobile .RecentPlacements-filterItem {
  border-bottom: 1px solid #cdcbcb;
  width: 100%;
}
.RecentPlacements-filterModel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.isMobile .RecentPlacements-filterModel {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 12px 0 16px;
}
.RecentPlacements-filterModel span {
  font-family: "FFMarkWebPro";
  font-size: 18px;
  color: #f5371f;
  font-weight: 500;
  pointer-events: none;
  letter-spacing: -1px;
}
.RecentPlacements-filterModel svg {
  width: 15px;
  height: 9px;
  margin-left: 16px;
  margin-top: 6px;
  fill: #f5371f;
  pointer-events: none;
}
.RecentPlacements-filterModel:hover span {
  text-decoration: underline;
}
.RecentPlacements-filterModel.active + .RecentPlacements-filterOptions {
  display: block;
}
.RecentPlacements-filterModel.active svg {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.RecentPlacements-filterOptions {
  position: absolute;
  display: none;
  top: 32px;
  background-color: #f5371f;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  left: -20px;
  z-index: 5;
  max-height: 500px;
  overflow-y: scroll;
}
.RecentPlacements-filterOption {
  font-family: "FFMarkWebPro";
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  padding: 7px 19px 11px 19px;
  border-bottom: 1px solid #f75f4c;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
  white-space: pre;
  letter-spacing: -1px;
}
.RecentPlacements-filterOption.needPadding {
  padding-left: 30px;
}
.RecentPlacements-filterOption.active {
  color: #ff9689;
}
.RecentPlacements-filterOption:hover {
  opacity: 0.7;
}
.RecentPlacements-filterSelect {
  display: none;
}
.isMobile .RecentPlacements-filterSelect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 25;
  display: inherit;
  opacity: 0;
}
.RecentPlacements-clearFilters {
  font-family: "FFMarkWebPro";
  font-size: 18px;
  color: #383633;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  right: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -1px;
}
.RecentPlacements-clearFilters:hover {
  text-decoration: underline;
}
.isMobile .RecentPlacements-clearFilters {
  position: relative;
  margin-top: 12px;
}
.RecentPlacements-list {
  padding: 45px 60px 0 60px;
  width: 100%;
  position: relative;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.RecentPlacements-list--loading {
  opacity: 0;
}
.isMobile .RecentPlacements-list {
  padding: 0 20px;
}
.RecentPlacements-list:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #ffffff;
}
.RecentPlacements-listRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.RecentPlacements-listRow--hidden {
  display: none;
}
.RecentPlacements-listItem {
  -ms-flex-preferred-size: 21%;
      flex-basis: 21%;
  border-bottom: 1px solid #cccccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 31px 0 31px 0;
  height: 300px;
  margin-left: 66px;
}
.isMobile .RecentPlacements-listItem {
  -ms-flex-preferred-size: 42%;
      flex-basis: 42%;
  margin-right: 0;
  margin-left: 0;
}
.isMobile .RecentPlacements-listItem:nth-child(2n + 1) {
  margin-right: 42px;
}
.RecentPlacements-listItem:nth-child(4n + 5),
.RecentPlacements-listItem:first-child {
  margin-left: 0;
}
.RecentPlacements-listLogo {
  height: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.isMobile .RecentPlacements-listLogo {
  position: relative;
  max-width: 200px;
}
.RecentPlacements-listLogo img {
  max-width: 70%;
  max-height: 100%;
}
.isMobile .RecentPlacements-listLogo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  top: auto;
  left: auto;
  max-width: none;
  height: auto;
}
.RecentPlacements-listPosition {
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  color: #383633;
  margin-top: 20px;
  text-align: center;
  letter-spacing: -0.05em;
}
.RecentPlacements-listLocation {
  font-family: "CharterITCStd";
  font-size: 14px;
  color: #999999;
  margin-top: 20px;
}
.RecentPlacements-zeroResults {
  width: 100%;
  text-align: center;
  font-family: "FFMarkWebPro";
  font-size: 80px;
  padding: 100px 0;
  letter-spacing: -4px;
  font-weight: 500;
  color: #bfbfbf;
}
.isMobile .RecentPlacements-zeroResults {
  font-size: 50px;
  padding: 40px 0 0;
}
