*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-scrolling: touch;
}
@media (max-width: 1179px) {
  html {
    overflow-x: auto;
  }
}
body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern";
  -ms-font-feature-settings: "kern" 1;
  font-feature-settings: "kern";
  overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}
body.isMobile {
  min-width: 100%;
}
.PageSection {
  -webkit-transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out;
  transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.PageSection.isOpenMenu {
  -webkit-transform: translateX(30%);
      -ms-transform: translateX(30%);
          transform: translateX(30%);
}
.isMobile .Section-content.overlay:after {
  content: '';
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 18;
  background-color: #ffffff;
}
.Block {
  padding-left: 100px;
  padding-right: 100px;
}
.isMobile .Block {
  padding-left: 20px;
  padding-right: 20px;
}
.Block.leftCirclePoint {
  -webkit-mask-image: radial-gradient(circle at left 140px, transparent 10px, black 10px);
          mask-image: radial-gradient(circle at left 140px, transparent 10px, black 10px);
}
.isMobile .Block.leftCirclePoint {
  -webkit-mask-image: radial-gradient(circle at left 65px, transparent 5px, black 5px);
          mask-image: radial-gradient(circle at left 65px, transparent 5px, black 5px);
}
.leftCirclePoint {
  -webkit-mask-image: radial-gradient(circle at left 140px, transparent 10px, black 10px);
          mask-image: radial-gradient(circle at left 140px, transparent 10px, black 10px);
}
.isMobile .leftCirclePoint {
  -webkit-mask-image: radial-gradient(circle at left 65px, transparent 5px, black 5px);
          mask-image: radial-gradient(circle at left 65px, transparent 5px, black 5px);
}
.Content {
  min-width: 1120px;
  min-height: 100vh;
}
.isMobile .Content {
  min-width: 100%;
}
.Content-clientLoginButton {
  position: absolute;
  bottom: 79px;
  left: 100px;
}
.Content-cookiesWarning {
  position: fixed;
  top: -62px;
  opacity: 0;
  left: 50%;
  margin-left: -460px;
  width: 920px;
  height: 60px;
  background-color: #383633;
  z-index: 40;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation: 0.2s cookiesShow 1s forwards ease-out;
          animation: 0.2s cookiesShow 1s forwards ease-out;
}
@-webkit-keyframes cookiesShow {
  0% {
    top: -62px;
    opacity: 0;
    visibility: hidden;
  }
  1% {
    top: -62px;
    opacity: 0;
    visibility: visible;
  }
  100% {
    top: 20px;
    opacity: 1;
    visibility: visible;
  }
}
@keyframes cookiesShow {
  0% {
    top: -62px;
    opacity: 0;
    visibility: hidden;
  }
  1% {
    top: -62px;
    opacity: 0;
    visibility: visible;
  }
  100% {
    top: 20px;
    opacity: 1;
    visibility: visible;
  }
}
.Content-cookiesWarning.hide {
  -webkit-animation: 0.2s cookiesHide forwards ease-out;
          animation: 0.2s cookiesHide forwards ease-out;
}
@-webkit-keyframes cookiesHide {
  0% {
    top: 20px;
    opacity: 1;
    visibility: visible;
  }
  99% {
    top: -62px;
    opacity: 0;
    visibility: visible;
  }
  100% {
    top: -62px;
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes cookiesHide {
  0% {
    top: 20px;
    opacity: 1;
    visibility: visible;
  }
  99% {
    top: -62px;
    opacity: 0;
    visibility: visible;
  }
  100% {
    top: -62px;
    opacity: 0;
    visibility: hidden;
  }
}
.isMobile .Content-cookiesWarning.hide {
  opacity: 0;
  visibility: hidden;
}
.isMobile .Content-cookiesWarning {
  width: 93%;
  min-width: unset;
  height: auto;
  bottom: 10px;
  padding-top: 0;
  opacity: 1;
  left: 3.5%;
  margin-left: 0;
  top: unset;
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
  -webkit-animation: none;
          animation: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.Content-cookiesWarning span {
  color: #d6d2c8;
  font-family: "FFMarkWebPro";
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.01em;
  padding: 0 160px 0 20px;
}
.isMobile .Content-cookiesWarning span {
  font-size: 14px;
  width: 100%;
  margin: 0;
  line-height: 18px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 20px;
}
.Content-cookiesWarning span a {
  color: #d6d2c8;
  font-family: "FFMarkWebPro";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 18px;
}
.Content-cookiesWarning span a:visited {
  color: #d6d2c8;
}
.isMobile .Content-cookiesWarning span a {
  font-size: 14px;
}
.Content-cookiesWarningAccept {
  height: 100%;
  width: 160px;
  position: absolute;
  right: 0;
  border-radius: 0 5px 5px 0;
  top: 0;
  color: #ffffff;
  background-color: #f5371f;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.05em;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 7px;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}
.Content-cookiesWarningAccept:hover {
  opacity: 0.7;
}
.isMobile .Content-cookiesWarningAccept {
  height: 55px;
  bottom: 0;
  width: 100%;
  top: auto;
  border: none;
  left: 0;
  right: 0;
  background: none;
  color: #f5371f;
  position: relative;
}
.Content .isInitialState .Content-wrapperInner {
  opacity: 0;
}
.Content.TruePage,
.Content.Page404 {
  background: #d6d2c8;
}
.Content.SearchPage {
  background: #b4463d;
}
.Content.TechPage {
  background: #51a29e;
}
.Content.DevPage {
  background: #142f81;
}
.Content-body {
  position: relative;
  min-height: 100vh;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.Content-body.true.isHover {
  background: #d6d2c8 !important;
}
.Content-body.search.isHover {
  background: #b4463d !important;
}
.Content-body.tech.isHover {
  background: #51a29e !important;
}
.Content-body.dev.isHover {
  background: #142f81 !important;
}
.Content-content {
  position: relative;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out;
  transition: 0.2s transform ease-out, 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
}
.Content-content.isOpenMenu {
  -webkit-transform: translateX(30%);
      -ms-transform: translateX(30%);
          transform: translateX(30%);
}
.Content-outter {
  height: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 11;
  margin-left: auto;
  width: 77.77777778%;
  pointer-events: none;
}
.SearchExtraPage .Content-outter,
.TrueSlackPage .Content-outter {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
}
.isMobile .SearchExtraPage .Content-outter,
.isMobile .TrueSlackPage .Content-outter {
  padding: 0;
}
@media (min-width: 1920px) {
}
@media (min-width: 1180px) and (max-width: 1380px) {
}
@media (max-width: 1179px) {
}
.isMobile .Content-outter {
  padding-left: 20px;
  width: 100%;
}
.Content-wrapper {
  width: 100%;
  will-change: transform;
  -webkit-transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out;
  transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
  pointer-events: all;
}
.noTransition .Content-wrapper {
  -webkit-transition: none;
  transition: none;
}
.IndexPage .Content-wrapper,
.SearchPage .Content-wrapper,
.DevPage .Content-wrapper,
.TechPage .Content-wrapper {
  margin-top: 80vh;
}
.isMobile .IndexPage .Content-wrapper,
.isMobile .SearchPage .Content-wrapper,
.isMobile .DevPage .Content-wrapper,
.isMobile .TechPage .Content-wrapper {
  margin-top: 75vh;
}
.JoinPage .Content-wrapper,
.TeamPage .Content-wrapper,
.SearchIndustriesPage .Content-wrapper,
.SearchAssetClassesPage .Content-wrapper,
.SearchPositionsPage .Content-wrapper,
.SearchGlobalReachPage .Content-wrapper {
  margin-top: 0;
}
.isMobile .JoinPage .Content-wrapper,
.isMobile .TeamPage .Content-wrapper,
.isMobile .SearchIndustriesPage .Content-wrapper,
.isMobile .SearchAssetClassesPage .Content-wrapper,
.isMobile .SearchPositionsPage .Content-wrapper,
.isMobile .SearchGlobalReachPage .Content-wrapper {
  margin-top: 60px;
}
.SearchExtraPage .Content-wrapper {
  margin-top: 72vh;
  background: #ffffff;
  width: 100%;
}
.isMobile .SearchExtraPage .Content-wrapper {
  margin-top: 75vh;
}
.isMobile .Content-wrapper {
  margin-top: 60px;
}
.Content-wrapperInner {
  max-width: 1650px;
  -webkit-transition: 0.2s opacity 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out;
}
.noTransition .Content-wrapperInner {
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.Content-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 1120px;
  z-index: 5;
  -webkit-transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out;
  transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
}
.isMobile .Content-background {
  min-width: 100%;
}
.Content-background.isOpenMenu {
  -webkit-transform: translateX(30%);
      -ms-transform: translateX(30%);
          transform: translateX(30%);
}
.Page404 .Content-backgroundOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 5;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(214, 210, 200, 0)), to(#d6d2c8));
  background: linear-gradient(to bottom, rgba(214, 210, 200, 0), #d6d2c8);
}
.Content-backgroundOverlayMenu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 580px;
  z-index: 8;
  height: 100%;
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: 0.5s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.5s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out, 0.5s opacity ease-out;
  transition: 0.2s transform ease-out, 0.5s opacity ease-out, 0.2s -webkit-transform ease-out;
  opacity: 0;
}
.Content-backgroundOverlayMenu.isOpenMenu {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.Content-backgroundOverlayMenu[data-id="true"],
.Page404 .Content-backgroundOverlayMenu[data-id="true"] {
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d2c8), to(rgba(214, 210, 200, 0)));
  background: linear-gradient(to right, #d6d2c8 50%, rgba(214, 210, 200, 0) 100%);
}
@media (min-width: 1920px) {
  .Content-backgroundOverlayMenu[data-id="true"],
  .Page404 .Content-backgroundOverlayMenu[data-id="true"] {
    background: -webkit-gradient(linear, left top, right top, color-stop(60%, #d6d2c8), to(rgba(214, 210, 200, 0)));
    background: linear-gradient(to right, #d6d2c8 60%, rgba(214, 210, 200, 0) 100%);
  }
}
.Content-backgroundOverlayMenu[data-id="search"] {
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #b4463d), to(rgba(180, 70, 61, 0)));
  background: linear-gradient(to right, #b4463d 50%, rgba(180, 70, 61, 0) 100%);
}
@media (min-width: 1920px) {
  .Content-backgroundOverlayMenu[data-id="search"] {
    background: -webkit-gradient(linear, left top, right top, color-stop(60%, #b4463d), to(rgba(180, 70, 61, 0)));
    background: linear-gradient(to right, #b4463d 60%, rgba(180, 70, 61, 0) 100%);
  }
}
.Content-backgroundOverlayMenu[data-id="tech"] {
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #51a29e), to(rgba(81, 162, 158, 0)));
  background: linear-gradient(to right, #51a29e 50%, rgba(81, 162, 158, 0) 100%);
}
@media (min-width: 1920px) {
  .Content-backgroundOverlayMenu[data-id="tech"] {
    background: -webkit-gradient(linear, left top, right top, color-stop(60%, #51a29e), to(rgba(81, 162, 158, 0)));
    background: linear-gradient(to right, #51a29e 60%, rgba(81, 162, 158, 0) 100%);
  }
}
.Content-backgroundOverlayMenu[data-id="dev"] {
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #142f81), to(rgba(20, 47, 129, 0)));
  background: linear-gradient(to right, #142f81 50%, rgba(20, 47, 129, 0) 100%);
}
@media (min-width: 1920px) {
  .Content-backgroundOverlayMenu[data-id="dev"] {
    background: -webkit-gradient(linear, left top, right top, color-stop(60%, #142f81), to(rgba(20, 47, 129, 0)));
    background: linear-gradient(to right, #142f81 60%, rgba(20, 47, 129, 0) 100%);
  }
}
.TruePage .Content-backgroundOverlayMenu[data-id="true"],
.SearchPage .Content-backgroundOverlayMenu[data-id="search"],
.TechPage .Content-backgroundOverlayMenu[data-id="tech"],
.DevPage .Content-backgroundOverlayMenu[data-id="dev"] {
  opacity: 1;
}
.TruePage .isHover .Content-backgroundOverlayMenu[data-id="true"],
.true .isHover .Content-backgroundOverlayMenu[data-id="true"],
.Page404 .isHover .Content-backgroundOverlayMenu[data-id="true"],
.SearchPage .isHover .Content-backgroundOverlayMenu[data-id="search"],
.search .isHover .Content-backgroundOverlayMenu[data-id="search"],
.TechPage .isHover .Content-backgroundOverlayMenu[data-id="tech"],
.tech .isHover .Content-backgroundOverlayMenu[data-id="tech"],
.DevPage .isHover .Content-backgroundOverlayMenu[data-id="dev"],
.dev .isHover .Content-backgroundOverlayMenu[data-id="dev"] {
  opacity: 0;
  -webkit-transition: 0.2s opacity 0.2s linear;
  transition: 0.2s opacity 0.2s linear;
}
.Content-backgroundOverlayMenu.isHover {
  opacity: 1;
  z-index: 9;
  -webkit-transition: 0.5s opacity linear;
  transition: 0.5s opacity linear;
}
.Content-backgroundWrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 1120px;
}
.isMobile .Content-backgroundWrapper {
  min-width: 100%;
}
.Content-backgroundItem {
  width: 133%;
  height: 133%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -57%);
      -ms-transform: translate(-50%, -57%);
          transform: translate(-50%, -57%);
  position: relative;
}
.Content-backgroundItem:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 13;
  height: 100%;
  width: 150px;
  opacity: 1;
}
.isMobile .Content-backgroundItem:before {
  display: none;
}
.TruePage .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#d6d2c8), to(rgba(214, 210, 200, 0)));
  background-image: linear-gradient(to right, #d6d2c8, rgba(214, 210, 200, 0));
}
.SearchPage .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#b4463d), to(rgba(180, 70, 61, 0)));
  background-image: linear-gradient(to right, #b4463d, rgba(180, 70, 61, 0));
}
.DevPage .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#142f81), to(rgba(20, 47, 129, 0)));
  background-image: linear-gradient(to right, #142f81, rgba(20, 47, 129, 0));
}
.TechPage .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#51a29e), to(rgba(81, 162, 158, 0)));
  background-image: linear-gradient(to right, #51a29e, rgba(81, 162, 158, 0));
}
.true .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#d6d2c8), to(rgba(214, 210, 200, 0)));
  background-image: linear-gradient(to right, #d6d2c8, rgba(214, 210, 200, 0));
}
.search .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#b4463d), to(rgba(180, 70, 61, 0))) !important;
  background-image: linear-gradient(to right, #b4463d, rgba(180, 70, 61, 0)) !important;
}
.dev .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#142f81), to(rgba(20, 47, 129, 0))) !important;
  background-image: linear-gradient(to right, #142f81, rgba(20, 47, 129, 0)) !important;
}
.tech .Content-backgroundItem:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#51a29e), to(rgba(81, 162, 158, 0))) !important;
  background-image: linear-gradient(to right, #51a29e, rgba(81, 162, 158, 0)) !important;
}
.isMobile .Content-backgroundItem {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.Page404.isMobile .Content-backgroundItem {
  top: -13%;
}
.Content-backgroundItemImg {
  opacity: 0;
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-transition: 0.8s opacity ease-out;
  transition: 0.8s opacity ease-out;
}
.isMobile .Content-backgroundItemImg:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.isMobile .TruePage .Content-backgroundItemImg:after {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(214, 210, 200, 0)), color-stop(70%, #d6d2c8));
  background-image: linear-gradient(to bottom, rgba(214, 210, 200, 0) 60%, #d6d2c8 70%);
}
.isMobile .SearchPage .Content-backgroundItemImg:after {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(180, 70, 61, 0)), color-stop(70%, #b4463d));
  background-image: linear-gradient(to bottom, rgba(180, 70, 61, 0) 60%, #b4463d 70%);
}
.isMobile .DevPage .Content-backgroundItemImg:after {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(20, 47, 129, 0)), color-stop(60%, #142f81));
  background-image: linear-gradient(to bottom, rgba(20, 47, 129, 0) 50%, #142f81 60%);
}
.isMobile .TechPage .Content-backgroundItemImg:after {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(81, 162, 158, 0)), color-stop(70%, #51a29e));
  background-image: linear-gradient(to bottom, rgba(81, 162, 158, 0) 60%, #51a29e 70%);
}
.isMobile .Content-backgroundItemImg img {
  height: 70%;
}
.isMobile .DevPage .Content-backgroundItemImg img {
  height: 60%;
  left: -10%;
  width: 110%;
}
.TruePage .Content-backgroundItemImg--true,
.Page404 .Content-backgroundItemImg--true {
  opacity: 1;
  z-index: 11;
}
.SearchPage .Content-backgroundItemImg--search {
  opacity: 1;
  z-index: 11;
}
.DevPage .Content-backgroundItemImg--dev {
  opacity: 1;
  z-index: 11;
}
.TechPage .Content-backgroundItemImg--tech {
  opacity: 1;
  z-index: 11;
}
.isHoverBackground .Content-backgroundItemImg {
  opacity: 0;
}
.Content-backgroundItemImg.isHover {
  opacity: 1 !important;
  z-index: 12;
}
.Content-backgroundItemImg > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.Content-backgroundItemImgLetter {
  position: absolute;
  pointer-events: none;
}
.TruePage .Content-backgroundItemImgLetter,
.TechPage .Content-backgroundItemImgLetter {
  pointer-events: all;
}
.isDesktop .TruePage .Content-backgroundItemImgLetter:hover img,
.isDesktop .TechPage .Content-backgroundItemImgLetter:hover img {
  opacity: 1;
}
.DevPage .Content-backgroundItemImgLetter--dev .Content-backgroundItemImgLetter-hover {
  top: 13%;
  left: 11%;
}
.DevPage .Content-backgroundItemImgLetter--elo .Content-backgroundItemImgLetter-hover {
  top: 7%;
  left: 25%;
}
.DevPage .Content-backgroundItemImgLetter--opm .Content-backgroundItemImgLetter-hover {
  top: 7%;
  left: 25%;
}
.DevPage .Content-backgroundItemImgLetter--ent .Content-backgroundItemImgLetter-hover {
  top: 0%;
  right: 15%;
}
.Content-backgroundItemImgLetter-hover {
  position: absolute;
  pointer-events: all;
}
.SearchPage .Content-backgroundItemImgLetter-hover {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-55%, -50%);
      -ms-transform: translate(-55%, -50%);
          transform: translate(-55%, -50%);
  width: 35%;
  height: 50%;
}
.DevPage .Content-backgroundItemImgLetter-hover {
  width: 40%;
  height: 70%;
}
.isDesktop .Content-backgroundItemImgLetter-hover:hover + img {
  opacity: 1;
}
.Content-backgroundItemImgLetter img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
  opacity: 0;
}
.overflowLock {
  overflow: hidden !important;
}
.NeedFixOnPopup {
  min-width: 1120px;
  top: 0;
  will-change: top;
}
.isMobile .NeedFixOnPopup {
  min-width: 100%;
}
.FixedOnPopup {
  position: fixed !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 14 !important;
}
