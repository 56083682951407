.NewsPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 26;
  -webkit-transition: 0s visibility 0.2s ease-out;
  transition: 0s visibility 0.2s ease-out;
  overflow-scrolling: touch;
  visibility: hidden;
}
.NewsPopup--visible {
  visibility: visible;
  -webkit-transition: none;
  transition: none;
}
.NewsPopup--visible .NewsPopup-content {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out, 0.2s transform 0.2s ease-out, 0.2s -webkit-transform 0.2s ease-out;
}
.NewsPopup--visible .NewsPopup-contentInner {
  opacity: 1;
  -webkit-transition: 0.2s opacity 0.4s ease-out;
  transition: 0.2s opacity 0.4s ease-out;
}
.NewsPopup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.NewsPopup-wrapper {
  width: 75%;
  min-width: 500px;
  margin-left: auto;
  position: relative;
  overflow-scrolling: touch;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 11;
}
.isMobile .NewsPopup-wrapper {
  min-width: 100%;
  width: 100%;
}
.NewsPopup-wrapper.overflowLock {
  overflow: hidden;
}
.NewsPopup-content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #f4f3f1;
  outline: none;
  overflow-scrolling: touch;
  -webkit-transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s -webkit-transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out;
  transition: 0.2s opacity ease-out, 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}
.isMobile .NewsPopup-content {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.NewsPopup-contentInner {
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out;
  transition: 0.2s opacity ease-out;
}
.NewsPopup-close {
  position: fixed;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  z-index: 26;
}
.isMobile .NewsPopup-close {
  top: 20px;
  right: 20px;
}
.hasCover .NewsPopup-close {
  background: #ffffff;
}
.isMobile .hasCover .NewsPopup-close {
  background: #383633;
}
.NewsPopup-close:hover .NewsPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  width: 34px;
}
.NewsPopup-close:hover .NewsPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  width: 34px;
}
.NewsPopup-closeItem {
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  background: #ffffff;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.hasCover .NewsPopup-closeItem {
  background: #383633;
}
.isMobile .hasCover .NewsPopup-closeItem {
  background: #ffffff;
}
.NewsPopup-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.NewsPopup-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.isMobile .NewsPopup-close {
  display: block;
}
.NewsPopup-cover {
  width: 100%;
  position: relative;
}
.NewsPopup-coverImg {
  position: relative;
}
.NewsPopup-coverImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 48.14814815%;
}
.isMobile .NewsPopup-coverImg {
  position: relative;
}
.isMobile .NewsPopup-coverImg:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 66.66666667%;
}
.NewsPopup-coverImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  font-family: 'object-fit: cover; object-position: center';
}
.NewsPopup-header {
  width: 100%;
  padding: 65px 180px 70px 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #383633;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.isMobile .NewsPopup-header {
  padding: 68px 20px;
}
.hasCover .NewsPopup-header {
  background: none;
  padding: 45px 180px 31px 140px;
}
.isMobile .hasCover .NewsPopup-header {
  padding: 28px 20px 33px;
}
.NewsPopup-headerTag {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #808080;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 37px;
}
.isMobile .NewsPopup-headerTag {
  font-size: 16px;
  line-height: 20px;
}
.isMobile .NewsPopup-headerTag {
  margin-bottom: 14px;
}
.hasCover .NewsPopup-headerTag {
  margin-bottom: -3px;
}
.isMobile .hasCover .NewsPopup-headerTag {
  margin-bottom: 15px;
}
.NewsPopup-headerTitle {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  font-size: 60px;
  line-height: 60px;
  width: 94.73684211%;
  color: #ffffff;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isMobile .NewsPopup-headerTitle {
  font-size: 36px;
  line-height: 40px;
}
.isMobile .NewsPopup-headerTitle {
  width: 100%;
}
.hasCover .NewsPopup-headerTitle {
  color: #383633;
}
.NewsPopup-headerDate {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  color: #808080;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 77px;
}
.isMobile .NewsPopup-headerDate {
  margin-top: 40px;
}
.hasCover .NewsPopup-headerDate {
  margin-top: 33px;
}
.isMobile .hasCover .NewsPopup-headerDate {
  margin-top: 32px;
}
.NewsPopup-blocks {
  position: relative;
  width: 100%;
  padding: 90px 180px 145px 140px;
  min-height: 300px;
}
.isMobile .NewsPopup-blocks {
  min-height: 50px;
  padding: 40px 20px;
}
.hasCover .NewsPopup-blocks {
  padding: 0 180px 145px 140px;
}
.isMobile .hasCover .NewsPopup-blocks {
  padding: 0 20px 40px;
}
.NewsPopup-blocksBlock:first-child .CommonBlock {
  margin-top: 0;
}
