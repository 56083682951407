.TeamMembers {
  background-color: #ffffff;
  padding-bottom: 65px;
  padding-top: 65px;
}
.TeamMembers.isShortBlock {
  padding: 86px 100px 75px;
}
.isMobile .TeamMembers {
  padding: 39px 0 60px;
}
.TeamMembers-title {
  font-size: 80px;
  line-height: 80px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  text-transform: lowercase;
  margin-left: -4px;
  max-width: 800px;
  position: relative;
}
.isMobile .TeamMembers-title {
  font-size: 36px;
  line-height: 40px;
}
.TeamMembers-content {
  margin-top: 34px;
  font-family: "CharterITCStd";
}
.isMobile .TeamMembers-content {
  margin-top: 40px;
}
.TeamMembers-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isMobile .TeamMembers-filters {
  display: none;
}
.TeamMembers-filtersMobile {
  display: none;
}
.isMobile .TeamMembers-filtersMobile {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 40;
  background-color: #ffffff;
  overflow-y: scroll;
  padding-bottom: 100px;
}
.isMobile .TeamMembers-filtersMobile.active {
  display: inherit;
}
.TeamMembers-filtersMobileButton {
  display: none;
}
.isMobile .TeamMembers-filtersMobileButton {
  display: inherit;
  position: absolute;
  outline: none;
  border: none;
  width: 80px;
  right: 0;
  top: 5px;
  padding-top: 10px;
  background-color: transparent;
  color: #f5371f;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  letter-spacing: -0.05em;
}
.TeamMembers-close {
  position: fixed;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  z-index: 50;
}
.isMobile .TeamMembers-close {
  top: 20px;
  right: 20px;
}
.TeamMembers-close:hover .TeamMembers-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  width: 34px;
}
.TeamMembers-close:hover .TeamMembers-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  width: 34px;
}
.TeamMembers-closeItem {
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  background: #383633;
}
.TeamMembers-closeItem--1 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(45deg);
          transform: translate3d(-50%, 0, 0) rotate(45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.TeamMembers-closeItem--2 {
  -webkit-transform: translate3d(-50%, 0, 0) rotate(-45deg);
          transform: translate3d(-50%, 0, 0) rotate(-45deg);
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.isMobile .TeamMembers-close {
  display: block;
}
.TeamMembers-radioButton {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #808080;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.TeamMembers-radioButtonWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.TeamMembers-radioButtonWrapper label {
  margin-left: 10px;
  font-size: 16px;
  color: #383633;
  font-weight: 500;
  letter-spacing: -0.05em;
  font-family: "FFMarkWebPro";
}
.TeamMembers-radioButtonWrapper input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
}
.TeamMembers-radioButtonWrapper input:checked + .TeamMembers-radioButton:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #f5371f;
  border-radius: 50%;
}
.TeamMembers-filter {
  margin-right: 42px;
  position: relative;
}
.TeamMembers-filterMobile {
  padding: 50px 40px;
  border-bottom: 1px solid #e1e1e1;
}
.TeamMembers-filterMobile:last-of-type {
  border-bottom: none;
}
.TeamMembers-filter:last-child {
  margin-right: 0;
}
.TeamMembers-filterPlaceholder {
  margin-top: 112px;
}
.isMobile .TeamMembers-filterPlaceholder {
  display: none;
}
.TeamMembers-filterTitleMobile {
  font-size: 16px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  letter-spacing: -0.05em;
  color: #808080;
}
.TeamMembers-filterListWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-bottom: 20px;
  position: relative;
  border-bottom: 1px solid #bfbfbf;
}
.isMobile .TeamMembers-filterListWrapper {
  display: none;
}
.TeamMembers-filterListTitle {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #383633;
  letter-spacing: -0.05em;
  color: #bfbfbf;
  margin-right: 25px;
}
.isMobile .TeamMembers-filterListTitle {
  font-size: 16px;
  line-height: 20px;
}
.TeamMembers-filterValue {
  font-family: "FFMarkWebPro";
  font-size: 18px;
  color: #ff3333;
  font-weight: 500;
  letter-spacing: -1px;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
  text-transform: lowercase;
}
.DevPage .TeamMembers-filterValue {
  color: #3b6dca;
}
.TechPage .TeamMembers-filterValue {
  color: #3cbea6;
}
.TeamMembers-filterValue:hover span {
  text-decoration: underline;
}
.TeamMembers-filterValue svg {
  width: 15px;
  height: 8.5px;
  fill: #ff3333;
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  margin-left: 8px;
}
.DevPage .TeamMembers-filterValue svg {
  fill: #3b6dca;
}
.TechPage .TeamMembers-filterValue svg {
  fill: #3cbea6;
}
.TeamMembers-filterOptions {
  margin-top: 16.5px;
  visibility: hidden;
  position: absolute;
  top: 100%;
  background-color: #ff3333;
  width: 255px;
  left: -25px;
  z-index: 20;
}
.TeamMembers-filterOptionsMobile {
  margin-top: 20px;
}
.TeamMembers-filterOptions.active {
  -webkit-animation: optionsFadeIn 0.2s forwards ease-in-out;
          animation: optionsFadeIn 0.2s forwards ease-in-out;
}
@-webkit-keyframes optionsFadeIn {
  0% {
    visibility: hidden;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  1% {
    visibility: visible;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    visibility: visible;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes optionsFadeIn {
  0% {
    visibility: hidden;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  1% {
    visibility: visible;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    visibility: visible;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.TeamMembers-filterOptions.active + .TeamMembers-filterValue svg {
  -webkit-transform: translateZ(0) rotateX(-180deg);
          transform: translateZ(0) rotateX(-180deg);
}
.TeamMembers-filterOption {
  color: #ffffff;
  font-family: "FFMarkWebPro";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -1px;
  padding: 4px 19px 11px 19px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  cursor: pointer;
  text-transform: lowercase;
}
.TeamMembers-filterOptionMobile {
  margin-top: 20px;
}
.TeamMembers-filterOption.active span {
  opacity: 0.7;
}
.TeamMembers-filterOption.active:hover span {
  opacity: 0.7;
}
.TeamMembers-filterOption span {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  pointer-events: none;
}
.TeamMembers-filterOption:hover span {
  opacity: 0.7;
}
.TeamMembers-applyFilterMobile {
  width: 100%;
  position: fixed;
  height: 60px;
  background-color: #f5371f;
  color: #ffffff;
  font-family: "FFMarkWebPro";
  letter-spacing: -0.05em;
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0;
  outline: none;
  border: none;
  z-index: 25;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.TeamMembers-applyFilterMobile:hover {
  opacity: 0.7;
}
.isMobile .TeamMembers-membersList {
  width: 100%;
}
.TeamMembers-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 54px;
}
.isMobile .TeamMembers-list {
  margin-top: 0;
}
.TeamMembers-list a {
  text-decoration: none;
  display: block;
  margin-bottom: 65px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
}
.TeamMembers-list a:nth-child(3n + 1) {
  margin-right: 5%;
}
.TeamMembers-list a:nth-child(3n + 2) {
  margin-top: 125px;
  margin-right: 5%;
}
.TeamMembers-list a:nth-child(3n + 4) {
  margin-top: -100px;
}
.TeamMembers-list a:nth-child(3n + 5) {
  margin-top: 25px;
}
.TeamMembers-list a:nth-child(3n + 6) {
  margin-top: -100px;
}
.isMobile .TeamMembers-list a {
  margin-bottom: 24px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 46.6%;
          flex: 0 0 46.6%;
  margin-top: 0;
  margin-right: 0;
  width: auto;
}
.isMobile .TeamMembers-list a:nth-child(2n + 1) {
  margin-right: 6.8%;
}
.TeamMembers-listColumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-right: 40px;
}
.isMobile .TeamMembers-listColumn {
  margin-right: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.TeamMembers-listColumn:last-child {
  margin-right: 0;
}
.TeamMembers-listColumn:nth-child(2) {
  margin-top: 125px;
}
.isMobile .TeamMembers-listColumn:nth-child(2) {
  margin-top: 0;
}
.TeamMembers-listColumn:nth-child(3) {
  margin-top: -73px;
}
.isMobile .TeamMembers-listColumn:nth-child(3) {
  margin-top: 0;
}
.TeamMembers-member {
  width: 100%;
  margin-bottom: 48px;
}
.isMobile .TeamMembers-member {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 46.6%;
          flex: 0 0 46.6%;
  width: auto;
}
.TeamMembers-member:hover .TeamMembers-memberAvatar img {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.TeamMembers-member:hover .TeamMembers-memberName {
  color: #f5371f;
}
.DevPage .TeamMembers-member:hover .TeamMembers-memberName {
  color: #3b6dca;
}
.TechPage .TeamMembers-member:hover .TeamMembers-memberName {
  color: #3cbea6;
}
.TeamMembers-member:last-child {
  margin-bottom: 0;
}
.TeamMembers-memberAvatar {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
}
.TeamMembers-memberAvatar img {
  width: 100%;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.TeamMembers-memberAvatarPlaceholder {
  width: 100%;
  padding-bottom: 100%;
  background-color: #e5e4df;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.TeamMembers-memberAvatarPlaceholder:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.TeamMembers-memberAvatarPlaceholderContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
}
.TeamMembers-memberAvatarPlaceholderContent div {
  display: table;
  width: 100%;
  height: 100%;
}
.TeamMembers-memberAvatarPlaceholderContent span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 120px;
  font-family: "FFMarkWebPro";
  color: #ffffff;
  font-weight: 500;
}
.isMobile .TeamMembers-memberAvatarPlaceholderContent span {
  font-size: 24.30555556vw;
}
.TeamMembers-memberName {
  margin-top: 20px;
  font-size: 36px;
  font-family: "CharterITCStd";
  color: #333333;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.isMobile .TeamMembers-memberName {
  font-size: 24px;
  margin-top: 12px;
}
.TeamMembers-memberTeam {
  font-size: 18px;
  font-family: "FFMarkWebPro";
  color: #f5371f;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-top: 8px;
}
.DevPage .TeamMembers-memberTeam {
  color: #3b6dca;
}
.TechPage .TeamMembers-memberTeam {
  color: #3cbea6;
}
.isMobile .TeamMembers-memberTeam {
  margin-top: 5px;
  font-size: 16px;
}
.TeamMembers-memberTeam span {
  font-size: 14px;
  line-height: 20px;
  font-family: "CharterITCStd";
  font-weight: normal;
  color: #383633;
  letter-spacing: 0em;
  color: #808080;
}
.TeamMembers-memberPosition {
  font-size: 18px;
  font-family: "CharterITCStd";
  color: #999999;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin-top: 5px;
}
.isMobile .TeamMembers-memberPosition {
  font-size: 14px;
}
.TeamMembers-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  color: #f5371f;
  font-weight: 500;
  font-family: "FFMarkWebPro";
  letter-spacing: -0.05em;
  font-size: 18px;
}
.TeamMembers-link:hover {
  text-decoration: underline;
}
.DevPage .TeamMembers-link {
  color: #3b6dca;
}
.TechPage .TeamMembers-link {
  color: #3cbea6;
}
.isMobile .TeamMembers-link {
  font-size: 16px;
  margin-top: 25px;
  display: block;
}
.TeamMembers-clearFilters {
  font-family: "FFMarkWebPro";
  font-size: 18px;
  color: #383633;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 125%;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  letter-spacing: -1px;
}
.TeamMembers-clearFilters:hover {
  text-decoration: underline;
}
.TeamMembers-zeroResults {
  font-family: "FFMarkWebPro";
  font-weight: 500;
  color: #cccccc;
  font-size: 80px;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 50px;
  letter-spacing: -0.05em;
}
.TeamMembers-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.TeamMembers-search--mobile {
  display: none;
}
.isMobile .TeamMembers-search--mobile {
  display: block;
}
.TeamMembers-searchWrapper {
  position: absolute;
  right: 0;
  bottom: -1px;
  padding-bottom: 20px;
  width: 27.17391304%;
  border-bottom: 1px solid #383633;
}
.isMobile .TeamMembers-searchWrapper {
  position: relative;
  bottom: auto;
  right: auto;
  width: 100%;
  margin-top: 27px;
  padding-bottom: 5px;
}
.TeamMembers-searchIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  cursor: pointer;
  margin-bottom: -5px;
}
.isMobile .TeamMembers-searchIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.TeamMembers-searchIcon svg {
  width: 100%;
  height: 100%;
  fill: #383633;
}
.TeamMembers-searchInput {
  font-size: 18px;
  line-height: 30px;
  font-family: "FFMarkWebPro";
  font-weight: 500;
  letter-spacing: -0.05em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  outline: none;
  border: none;
  width: 75%;
  background: transparent;
  border-radius: 0;
  color: #383633;
}
.isMobile .TeamMembers-searchInput {
  font-size: 16px;
  line-height: 20px;
}
.isMobile .TeamMembers-searchInput {
  width: 78.57142857%;
  font-size: 18px;
  line-height: 30px;
}
.TeamMembers-searchInput::-webkit-input-placeholder {
  color: #383633;
}
.TeamMembers-searchInput::-moz-placeholder {
  color: #383633;
}
.TeamMembers-searchInput:-ms-input-placeholder {
  color: #383633;
}
.TeamMembers-searchInput::-ms-input-placeholder {
  color: #383633;
}
.TeamMembers-searchInput::placeholder {
  color: #383633;
}
.TeamMembers-searchInput::-webkit-input-placeholder {
  color: #383633;
}
.TeamMembers-searchInput::-moz-placeholder {
  color: #383633;
}
.TeamMembers-searchInput:-moz-placeholder {
  color: #383633;
}
.TeamMembers-searchInput:-ms-input-placeholder {
  color: #383633;
}
.TeamMembers--no-filters .TeamMembers-filterPlaceholder {
  margin-top: 32px;
}
